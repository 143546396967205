<template>
    <div class="modal fade show" id="addgeonotificationmodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-modal="true" style="display: none">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t("common_question") }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>{{ $t("analytics_associated_geonotificationOnWidgetModalText") }}</p>
                </div>
                <div class="modal-footer">
                    <button id="confirmAddGeonotificationNoButton" type="button" @click="noGeonotificationButton" class="btn btn-secondary" data-dismiss="modal">{{ $t("common_no") }}</button>
                    <button id="confirmAddGeonotificationYesButton" type="button" @click="yesGeonotificationButton" data-dismiss="modal" class="btn btn-brand">{{ $t("common_yes") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    data() {
        return {
            keepCategoriesId: []
        }
    },
    watch: {
        siteAssetCategories: function() {
            console.log("Component(addgeonotificationwidgetmodal)::watch(siteCategories) - called with : ", this.siteAssetCategories);
            // Set the value of keepCategoriesId
            var self = this;
            const keepCategories = _.reject(this.siteAssetCategories, function(category) {
                return !category.isPeopleCategory
            });

            this.keepCategoriesId = _.map(keepCategories, "id");
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["lastCreatedThresholdWidgetSiteId", "lastCreatedThresholdWidgetGeofenceId", "lastCreatedThresholdWidgetThreshold", "siteAssetCategories"])
    },

    methods: {
        ...mapActions([
            "hideAddGeonotificationModal"
        ]),

        yesGeonotificationButton() {
            console.log("Component(confirmLeavePageModal)::confirmLeavePageButton() - called");
            this.$router.push({
                name: "newGeonotification",
                params: {
                    siteId: this.lastCreatedThresholdWidgetSiteId,
                    geofence: this.lastCreatedThresholdWidgetGeofenceId,
                    threshold: this.lastCreatedThresholdWidgetThreshold,
                    condition: "THRESHOLD",
                    trigger: "assetCategory",
                    assetCategories: this.keepCategoriesId
                }
            });
        },

        noGeonotificationButton() {
            console.log("Component(cancelLeavePageModal)::cancelLeavePageButton() - called");
            this.hideAddGeonotificationModal();
        }
    }

};
</script>

<style scoped></style>
