<template>
    <div class="generic-widget-counter">
        <div class="kt-portlet kt-portlet--height-fluid" :class="isDarkMode ? 'portletDark' : ''">
            <div class="kt-widget14">
                <div class="genericWidgetHeader">
                    <div class="kt-widget14__header">
                        <div class="kt-widget14__title genericWidgetTitle" :class="isDarkMode ? 'textWhiteForDark' : ''">
                            {{ this.widgetTitle }}
                        </div>
                        <div class="kt-widget14__desc genericWidgetTitle" :class="isDarkMode ? 'textWhiteForDark' : ''">
                            <i v-if="widgetTimeWindow" class="la la-clock-o marginR5"></i>
                            <span v-if="widgetTimeWindow == 'hour'">{{ $tc("analytics_searchTW_hours", 1) }}</span>
                            <span v-else-if="widgetTimeWindow == '12H'">{{ $tc("analytics_searchTW_hours", 12) }}</span>
                            <span v-else-if="widgetTimeWindow == '24H'">{{ $tc("analytics_searchTW_hours", 24) }}</span>
                            <span v-else-if="widgetTimeWindow == '5days'">{{ $tc("analytics_searchTW_days", 5) }}</span>
                            <span v-else-if="widgetTimeWindow == '15days'">{{ $tc("analytics_searchTW_days", 15) }}</span>
                        </div>
                    </div>
                    <div class="genericWidgetToolbar">
                        <a href="#" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('common_drag')" class="btn btn-clean btn-icon btn-sm btn-icon-md moreButtonChartMain draggable-handle" :class="isDarkMode ? 'backgroundMoreBtnDarkHover' : ''">
                            <i class="flaticon2-cube-1" :class="isDarkMode ? 'textWhiteForDark' : ''"></i>
                        </a>
                        <div data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('common_moreActions')" class="dropdown dropdown-inline">
                            <button type="button" class="btn btn-clean btn-icon btn-sm btn-icon-md moreButtonChartMain" :class="isDarkMode ? 'backgroundMoreBtnDarkHover' : ''" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="flaticon-more moreButtonChart" :class="isDarkMode ? 'textWhiteForDark' : ''"></i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" href="#" @click="onEditWidget"><i class="la la-cog"></i> {{ $t("analytics_settings") }}</a>
                                <a class="dropdown-item" href="#" @click="onDeleteWidget"><i class="la la-trash"></i> {{ $t("analytics_delete") }} </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="kt-widget14__content genericWidgetContentChart" :class="isDarkMode ? 'textWhiteForDark' : ''">
                    <div class="kt-widget14__chart" style="width: 100%; margin-top: -15px;">
                        <div class="row align-items-center" style="padding: 7px 0;">
                            <div class="col-6 kt-font-bold kt-font-xl"> {{$t("analytics_ROI_nbr_searches")}}</div>
                            <div class="col-6 kt-font-bold kt-font-brand kt-font-xl" style="text-align: right;"> {{numberOfSearches}}</div>
                        </div>
                        <div class="row align-items-center" style="padding: 7px 0;">
                            <div class="col-6 kt-font-bold kt-font-xl"> {{$t("analytics_ROI_timeSaved")}}</div>
                            <div class="col-6 kt-font-bold kt-font-brand kt-font-xl" style="text-align: right;"> {{timeSavedDisplay}}</div>
                        </div>
                        <div class="row align-items-center" style="padding: 7px 0;">
                            <div class="col-9 kt-font-bold kt-font-xl">{{$t("analytics_ROI_moneySaved")}}</div>
                            <div class="col-3 kt-font-bold kt-font-success kt-font-xl" style="text-align: right;"> {{moneySavedDisplay}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
export default {
    data() {
        //console.log("Component(widgetROI)::data() - called");
        return {
            widgetContentData: null,
            colorPalette: [
                "#007bff",
                "#6610f2",
                "#6f42c1",
                "#e83e8c",
                "#dc3545",
                "#fd7e14",
                "#ffc107",
                "#28a745",
                "#20c997",
                "#17a2b8",
                "#6c757d",
                "#5867dd",
                "#0abb87",
                "#5578eb",
                "#ffb822",
                "#fd397a"
            ],
            widgetColor1: "",
            widgetColor2: "",
            widgetColor3: "",
            widgetTitle: null,
            widgetDesc: null,

            numberOfSearches: null,
            timeSaved: null,
            moneySaved: null,
            timeSavedDisplay: '',
            moneySavedDisplay: '',
            details: {},

            refreshInterval: null,
            refreshSchedulerId: null,
            isOngoingFetch: false,
            widgetTimeWindow: null
        };
    },
    created: function() {
        console.log("Component(widgetROI)::created() - called - widgetId: " + this.widgetId);
        this.colorPalette.sort(function() {return 0.5 - Math.random()});
        this.widgetColor1 = this.colorPalette[0];
        this.widgetColor2 = this.colorPalette[1];
        this.widgetColor3 = this.colorPalette[2];
        this.getWidgetTitle();
    },
    destroyed: function() {
        console.log("Component(widgetROI)::destroyed() - called - widgetId: " + this.widgetId);
        if (this.refreshSchedulerId) {
            clearInterval(this.refreshSchedulerId);
        }
    },
    props: {
        widgetId: {
            type: String
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        currentDashboardWidgets: {
            immediate: true,
            deep: true,
            handler(newValue) {
                this.getWidgetTitle(newValue);
            }
        },

        widgetsDrawingContentData: {
            //immediate: true,
            deep: true,
            handler(newValue) {
                var thisWidgetContent = this.getThisWidgetDrawingData(newValue);
                if (thisWidgetContent) {
                    var thisWidget = this.getThisWidget();
                    this.widgetTimeWindow = thisWidget.queryParams.timeWindow;
                    this.widgetContentData = thisWidgetContent.drawingData;
                } else {
                    this.getWidgetContent(this.widgetId);
                }
            }
        },

        widgetContentData: {
            //immediate: true,
            deep: true,
            handler: function(content) {
                if (content && content.hasOwnProperty("totalNumber")) {
                    this.numberOfSearches = parseInt(content.totalNumber);
                    var searchMeanTime = parseInt(this.details.searchMeanTime);
                    var avgHourSalary = parseFloat(this.details.avgHourSalary);
                    var currency = this.details.currency || '$';
                    if (!isNaN(this.numberOfSearches)) {
                        if (searchMeanTime > 0) {
                            this.timeSaved = parseInt(searchMeanTime * this.numberOfSearches);
                            if (avgHourSalary >= 0) {
                                this.moneySaved = parseInt(this.timeSaved*avgHourSalary/60);
                                this.moneySavedDisplay = this.moneySaved ? (currency + this.moneySaved) : 'N/A'
                            } else {
                                this.moneySaved = null;
                            }
                            this.formatTimeSaved();
                        } else {
                            this.timeSaved = null;
                            this.moneySaved = null;
                        }
                    } else {
                    }
                }
            }
        },

        currentDashboard: {
            immediate: true,
            deep: true,
            handler(newValue) {
                this.refreshInterval = newValue ? parseInt(newValue.refreshInterval) || null : null;
            }
        },

        refreshInterval: {
            immediate: true,
            handler(newValue) {
                console.log("Component(widgetROI)::watch(refreshInterval) handle with :", newValue);
                this.scheduleRefresh();
            }
        },

        widgetsLoadingState: {
            //deep: true,
            immediate: true,
            handler(newValue) {
                if (newValue.hasOwnProperty(this.widgetId)) {
                    this.isOngoingFetch = newValue[this.widgetId] ? true : false;
                } else if (this.refreshSchedulerId) {
                    // cancel scheduler
                }
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters([
            "currentDashboard",
            "currentDashboardWidgets",
            "widgetsDrawingContentData",
            "widgetsLoadingState",
            "isDarkMode"
        ])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["showDeleteModal", "getWidgetContent", "setWidgetToDetail", "setWidgetToEdit"]),

        onEditWidget: function() {
            this.setWidgetToEdit(this.widgetId);
        },

        onDeleteWidget: function () {
            this.showDeleteModal({ textContent: i18n.t("analytics_deleteWidgetConfirmText", { widgetName: this.widgetTitle }), actionName: "DELETE_WIDGET", params: { siteId: this.$route.params.siteId, dashboardId: this.$route.params.dashboardId, widgetId: this.widgetId } });
        },

        formatTimeSaved() {
            var nbrMinutes = parseInt(this.timeSaved%60);
            var nbrHours = parseInt((this.timeSaved - nbrMinutes)/60)%24;
            var nbrDays = parseInt(this.timeSaved/(24*60));
            this.timeSavedDisplay = "";
            if (nbrDays) {
                if (nbrDays == 1) {
                    this.timeSavedDisplay += nbrDays + i18n.t("analytics_attr_day");
                } else {
                    this.timeSavedDisplay += nbrDays + i18n.t("analytics_attr_days");
                }
            }
            if (nbrHours) {
                if (nbrHours == 1) {
                    this.timeSavedDisplay += " " + nbrHours + " " + i18n.tc("analytics_attr_hour");
                } else {
                    this.timeSavedDisplay += " " + nbrHours + " " + i18n.tc("analytics_attr_hours");
                }
            }
            if (nbrMinutes) {
                if (nbrMinutes == 1) {
                    this.timeSavedDisplay += " " + nbrMinutes + " " + i18n.tc("analytics_attr_minute");
                } else {
                    this.timeSavedDisplay += " " + nbrMinutes + " " + i18n.tc("analytics_attr_minutes");
                }
            }
            this.timeSavedDisplay = this.timeSavedDisplay || 'N/A';
        },

        getThisWidget: function(listWidgets) {
            return (listWidgets || this.currentDashboardWidgets).filter(
                function(widgetObject) {
                    return widgetObject.id === this.widgetId;
                },
                this
            )[0];
        },

        getThisWidgetDrawingData: function(data) {
            return (data || this.widgetsDrawingContentData).filter(function(
                widgetContent
            ) {
                return widgetContent.widgetId === this.widgetId;
            },
            this)[0];
        },

        scheduleRefresh: function() {
            if (this.refreshSchedulerId) {
                clearInterval(this.refreshSchedulerId);
            }
            this.smartFetchData();
            if (parseInt(this.refreshInterval)) {
                var self = this;
                this.refreshSchedulerId = setInterval(function() {
                    console.log("Component(widgetROI)::scheduleRefresh() -> widget " + self.widgetId + " setInterval called at " + self.refreshInterval + " seconds");
                    self.smartFetchData();
                }, parseInt(this.refreshInterval) * 1000);
            }
        },

        smartFetchData() {
            if (!this.isOngoingFetch) {
                this.getWidgetContent(this.widgetId);
            }
        },

        displayDetails() {
            this.setWidgetToDetail(this.widgetId);
        },

        formatWidgetTitle(thisWidget) {
            var isDefaultName = thisWidget.widgetType.defaultNameKey === thisWidget.customNameToDisplay;
            this.widgetTitle = isDefaultName ? i18n.t(thisWidget.customNameToDisplay) : thisWidget.customNameToDisplay;
        },

        getWidgetTitle(newValue) {
            const thisWidget = this.getThisWidget(newValue);
            if (thisWidget) {
                this.formatWidgetTitle(thisWidget);
                this.widgetDesc = thisWidget.widgetType.defaultNameKey + "_desc";
                this.details = thisWidget.detailsInfo || {};
            }
        }
    }
};
</script>

<style scoped>
.generic-widget-counterxx {
    font-size: 100px;
}
.wg-counter-title {
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 0;
    color: #6c7293;
}
.wg-counter-desc {
    display: inline-block;
    margin-top: 0.2rem;
    color: #a7abc3;
}
.wg-counter-head {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-right: 5px;
}
.wg-inner-counter {
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    justify-content: space-between;
}
</style>
