<template>
    <div class="generic-widget-counter">
        <div class="kt-portlet kt-portlet--height-fluid pieChart" :class="isDarkMode ? 'portletDark' : ''">
            <div class="kt-widget14">
                <div class="genericWidgetHeader">
                    <div class="kt-widget14__header">
                        <div class="kt-widget14__title genericWidgetTitle" :class="isDarkMode ? 'textWhiteForDark' : ''">
                            {{ this.widgetTitle }}
                        </div>
                        <div class="kt-widget14__desc genericWidgetTitle" :class="isDarkMode ? 'textWhiteForDark' : ''">
                            <span>{{ $t(this.widgetDesc, { geofenceName: this.widgetGeofenceName }) }}</span>
                        </div>
                    </div>
                    <div class="genericWidgetToolbar">
                        <a href="#" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('common_drag')" class="btn btn-clean btn-icon btn-sm btn-icon-md moreButtonChartMain draggable-handle" :class="isDarkMode ? 'backgroundMoreBtnDarkHover' : ''">
                            <i class="flaticon2-cube-1" :class="isDarkMode ? 'textWhiteForDark' : ''"></i>
                        </a>
                        <div data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('common_moreActions')" class="dropdown dropdown-inline">
                            <button type="button" class="btn btn-clean btn-icon btn-sm btn-icon-md moreButtonChartMain" :class="isDarkMode ? 'backgroundMoreBtnDarkHover' : ''" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="flaticon-more moreButtonChart" :class="isDarkMode ? 'textWhiteForDark' : ''"></i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right" style="">
                                <a class="dropdown-item" href="#" @click="displayDetails"><i class="la la-line-chart"></i> {{ $t("analytics_details") }}</a>
                                <a class="dropdown-item" href="#" @click="onEditWidget"><i class="la la-cog"></i> {{ $t("analytics_settings") }}</a>
                                <a class="dropdown-item" href="#" @click="onDeleteWidget"><i class="la la-trash"></i> {{ $t("analytics_delete") }} </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="genericWidgetContentChart blocCenterThreshold">
                    <p v-bind:style="{ color: widgetColor }">
                        {{ widgetContentData.assetsCount }}
                        <span :class="isDarkMode ? 'thresholdLabelLight' : 'thresholdLabelDark'">({{ $t("analytics_thresholdLabelAssets") }})</span>
                    </p>
                    <hr :class="isDarkMode ? 'thresholdHRLight' : 'thresholdHRDark'">
                    <p class="kt-margin-t-10" v-bind:style="{ color: widgetColor }">
                        {{ widgetContentData.maxThreshold }}
                        <span :class="isDarkMode ? 'thresholdLabelLight' : 'thresholdLabelDark'">({{ $t("analytics_thresholdLabelThreshold") }})</span>
                    </p>
                    <span class="btn threshholdBottom">
                        {{ $t("analytics_thresholdRemainingCapacity") }} {{ (widgetContentData.maxThreshold > widgetContentData.assetsCount ? widgetContentData.maxThreshold - widgetContentData.assetsCount : 0) }}
                    </span>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            widgetContentData: {},
            widgetDetails: null,
            colorPalette: [
                "#007bff",
                "#6610f2",
                "#6f42c1",
                "#e83e8c",
                "#dc3545",
                "#fd7e14",
                "#ffc107",
                "#28a745",
                "#20c997",
                "#17a2b8",
                "#ab1db3",
                "#5867dd",
                "#0abb87",
                "#5578eb",
                "#ffb822",
                "#fd397a"
            ],
            widgetColor: "#28a745",
            geofenceFullColor: "#dc3545",
            geofenceNotFullColor: "#28a745",
            geofenceNearlyFullColor: "#ffb822",
            widgetGeofenceName: "test",
            widgetTitle: null,
            widgetDesc: null,
            refreshInterval: null,
            refreshSchedulerId: null,
            isOngoingFetch: false,
            widgetTimeWindow: null
        };
    },
    created: function() {
        console.log("Component(widgetThreshold)::created() - called - widgetId: " + this.widgetId);
        this.getWidgetTitle();
    },
    destroyed: function() {
        console.log("Component(widgetThreshold)::destroyed() - called - widgetId: " + this.widgetId);
        if (this.refreshSchedulerId) {
            clearInterval(this.refreshSchedulerId);
        }
    },
    props: {
        widgetId: {
            type: String
        }
    },
    watch: {
        currentDashboardWidgets: {
            immediate: true,
            deep: true,
            handler(newValue) {
                this.getWidgetTitle(newValue);
            }
        },

        widgetsDrawingContentData: {
            //immediate: true,
            deep: true,
            handler(newValue) {
                var thisWidgetContent = this.getThisWidgetDrawingData(newValue);
                if (thisWidgetContent) {
                    var thisWidget = this.getThisWidget();
                    // Fetch the threshold
                    let maxThreshold = null;
                    if (thisWidget && thisWidget.queryParams && thisWidget.queryParams.assetThreshold) {
                        maxThreshold = thisWidget.queryParams.assetThreshold;
                        // Create the default threshold to 0
                        this.widgetContentData = {
                            assetsCount: 0,
                            maxThreshold: maxThreshold
                        };
                        this.widgetColor = this.geofenceNotFullColor;
                    }
                    // Fetch the number of asset inside the geofence
                    if (thisWidgetContent && thisWidgetContent.drawingData) {
                        const assetCount = thisWidgetContent.drawingData.length;
                        // Create the display of data
                        this.widgetContentData = {
                            assetsCount: assetCount,
                            maxThreshold: maxThreshold
                        };
                        // Calculate the percent of occupation to add the right color
                        const percent = parseInt((assetCount / maxThreshold) * 100);
                        // Analyse the percent
                        if (percent < 75) {
                            this.widgetColor = this.geofenceNotFullColor;
                        } else if (percent >= 75 && percent < 100) {
                            this.widgetColor = this.geofenceNearlyFullColor;
                        } else {
                            this.widgetColor = this.geofenceFullColor;
                        }
                    }
                } else {
                    this.getWidgetContent(this.widgetId);
                }
            }
        },

        currentDashboard: {
            immediate: true, // To force trigger watcher when the component is being created. Because it is set in viewDashoboard before the widgetThreshold creation
            deep: true, // Watcher whenever any of the watched object properties change regardless of their nested depth
            handler(newValue) {
                this.refreshInterval = newValue ? parseInt(newValue.refreshInterval) || null : null;
            }
        },

        refreshInterval: {
            immediate: true, // To force trigger watcher when the component is being created. Because it is set in viewDashboard before the widgetThreshold creation
            handler(newValue) {
                console.log("Component(widgetThreshold)::watch(refreshInterval) handle with :", newValue);
                this.scheduleRefresh();
            }
        },


        widgetsLoadingState: {
            //deep: true,
            immediate: true,
            handler(newValue) {
                if (newValue.hasOwnProperty(this.widgetId)) {
                    this.isOngoingFetch = newValue[this.widgetId] ? true : false;
                } else if (this.refreshSchedulerId) {
                    // cancel scheduler
                }
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters([
            "currentDashboard",
            "currentDashboardWidgets",
            "widgetsDrawingContentData",
            "widgetsLoadingState",
            "siteGeofences",
            "getGeofenceById",
            "isDarkMode"
        ])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["showDeleteModal", "getWidgetContent", "setWidgetToDetail", "setWidgetToEdit", "getSiteGeofences"]),

        onEditWidget: function() {
            // Go to update the widget
            this.setWidgetToEdit(this.widgetId);
        },

        onDeleteWidget: function () {
            this.showDeleteModal({ textContent: i18n.t("analytics_deleteWidgetConfirmText", { widgetName: this.widgetTitle }), actionName: "DELETE_WIDGET", params: { siteId: this.$route.params.siteId, dashboardId: this.$route.params.dashboardId, widgetId: this.widgetId } });
        },

        getThisWidget: function(listWidgets) {
            return (listWidgets || this.currentDashboardWidgets).filter(
                function(widgetObject) {
                    return widgetObject.id === this.widgetId;
                },
                this
            )[0];
        },

        getThisWidgetDrawingData: function(data) {
            return (data || this.widgetsDrawingContentData).filter(function(
                widgetContent
            ) {
                return widgetContent.widgetId === this.widgetId;
            },
            this)[0];
        },

        scheduleRefresh: function() {
            if (this.refreshSchedulerId) {
                clearInterval(this.refreshSchedulerId);
            }
            this.smartFetchData();
            if (parseInt(this.refreshInterval)) {
                var self = this;
                this.refreshSchedulerId = setInterval(function() {
                    console.log("Component(widgetThreshold)::scheduleRefresh() -> widget " + self.widgetId + " setInterval called at " + self.refreshInterval + " seconds");
                    self.smartFetchData();
                }, parseInt(this.refreshInterval) * 1000);
            }
        },

        smartFetchData() {
            if (!this.isOngoingFetch) {
                this.getWidgetContent(this.widgetId);
            }
        },

        displayDetails() {
            this.setWidgetToDetail(this.widgetId);
        },

        formatWidgetTitle(thisWidget) {
            const isDefaultName = thisWidget.widgetType.defaultNameKey === thisWidget.customNameToDisplay;
            this.widgetTitle = isDefaultName ? i18n.t(thisWidget.customNameToDisplay) : thisWidget.customNameToDisplay;
        },

        getWidgetTitle(newValue) {
            const thisWidget = this.getThisWidget(newValue);
            if (thisWidget) {
                this.formatWidgetTitle(thisWidget);
                this.widgetDesc = thisWidget.widgetType.defaultNameKey + "_desc";
                // The geofence of this widget
                const widgetGeofence = this.getGeofenceById(thisWidget.pathParams.GEOFENCE_ID);
                if (widgetGeofence) {
                    this.widgetGeofenceName = widgetGeofence.name;
                } else {
                    this.widgetGeofenceName = "";
                }
            }
        }
    }
};
</script>

<style scoped>
.blocCenterThreshold {
    font-size: 52px;
    font-weight: 500;
    text-align: center;
}
.thresholdHRLight {
    border: 1px solid white;
    margin: 0px 120px 0px 120px;
}
.thresholdHRDark {
    border: 1px solid #646c9a;
    width: 50%;
}
.thresholdLabelLight {
    font-size: 14px;
    color: white;
    position: absolute;
    margin-top: 39px;
    margin-left: 10px;
}
.thresholdLabelDark {
    font-size: 14px;
    color: #646c9a;
    position: absolute;
    margin-top: 39px;
    margin-left: 10px;
}
.threshholdBottom {
    font-size: 16px;
    background-color:#646c9a;
    color: white;
    margin-top: -35px;
}
</style>
