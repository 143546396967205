<template>
    <div class="generic-widget-counter">
        <div class="kt-portlet kt-portlet--height-fluid pieChart" :class="isDarkMode ? 'portletDark' : ''">
            <div class="kt-widget14">
                <div class="genericWidgetHeader">
                    <div class="kt-widget14__header">
                        <div class="kt-widget14__title genericWidgetTitle" :class="isDarkMode ? 'textWhiteForDark' : ''">
                            {{ this.widgetTitle }}
                        </div>
                        <div class="kt-widget14__desc genericWidgetTitle" :class="isDarkMode ? 'textWhiteForDark' : ''">
                            <i v-if="widgetTimeWindow" class="la la-clock-o marginR5"></i>
                            <span v-if="widgetTimeWindow == 'hour'">{{ $tc("analytics_searchTW_hours", 1) }}</span>
                            <span v-else-if="widgetTimeWindow == '12H'">{{ $tc("analytics_searchTW_hours", 12) }}</span>
                            <span v-else-if="widgetTimeWindow == '24H'">{{ $tc("analytics_searchTW_hours", 24) }}</span>
                            <span v-else-if="widgetTimeWindow == '5days'">{{ $tc("analytics_searchTW_days", 5) }}</span>
                            <span v-else-if="widgetTimeWindow == '15days'">{{ $tc("analytics_searchTW_days", 15) }}</span>
                            <span v-else>{{ $t(this.widgetDesc) }}</span>
                        </div>
                    </div>
                    <div class="genericWidgetToolbar">
                        <a href="#" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('common_drag')" class="btn btn-clean btn-icon btn-sm btn-icon-md moreButtonChartMain draggable-handle" :class="isDarkMode ? 'backgroundMoreBtnDarkHover' : ''">
                            <i class="flaticon2-cube-1" :class="isDarkMode ? 'textWhiteForDark' : ''"></i>
                        </a>
                        <div data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('common_moreActions')" class="dropdown dropdown-inline">
                            <button type="button" class="btn btn-clean btn-icon btn-sm btn-icon-md moreButtonChartMain" :class="isDarkMode ? 'backgroundMoreBtnDarkHover' : ''" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="flaticon-more moreButtonChart" :class="isDarkMode ? 'textWhiteForDark' : ''"></i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right">
                                <a v-if="hasDetails" class="dropdown-item" href="#" @click="displayDetails"><i class="la la-line-chart"></i> {{ $t("analytics_details") }}</a>
                                <a v-if="isEditable" class="dropdown-item" href="#" @click="onEditWidget"><i class="la la-cog"></i> {{ $t("analytics_settings") }}</a>
                                <a class="dropdown-item" href="#" @click="onDeleteWidget"><i class="la la-trash"></i> {{ $t("analytics_delete") }} </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="kt-widget14__content genericWidgetContentChart">
                    <div class="kt-widget14__chart">
                        <div class="genericWidgetContentText" v-bind:style="{ color: widgetColor }"> {{widgetContentData}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
export default {
    data() {
        //console.log("Component(widgetCounter)::data() - called");
        return {
            hasDetails: false,
            isEditable: false,
            widgetContentData: null,
            widgetDetails: null,
            colorPalette: [
                "#007bff",
                "#6610f2",
                "#6f42c1",
                "#e83e8c",
                "#dc3545",
                "#fd7e14",
                "#ffc107",
                "#28a745",
                "#20c997",
                "#17a2b8",
                "#ab1db3",
                "#5867dd",
                "#0abb87",
                "#5578eb",
                "#ffb822",
                "#fd397a"
            ],
            widgetColor: "#fd397a",
            widgetTitle: null,
            widgetDesc: null,
            refreshInterval: null,
            refreshSchedulerId: null,
            isOngoingFetch: false,
            widgetTimeWindow: null
        };
    },
    created: function() {
        console.log("Component(widgetCounter)::created() - called - widgetId: " + this.widgetId);
        this.widgetColor = this.colorPalette[Math.floor(Math.random() * this.colorPalette.length)];
        this.getWidgetTitle();
    },
    mounted: function() {
        // Init tooltips on mounted event
        $('[data-toggle="kt-tooltip"]').tooltip({ trigger: "hover" });
    },
    destroyed: function() {
        console.log("Component(widgetCounter)::destroyed() - called - widgetId: " + this.widgetId);
        if (this.refreshSchedulerId) {
            clearInterval(this.refreshSchedulerId);
        }
    },
    props: {
        widgetId: {
            type: String
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        currentDashboardWidgets: {
            immediate: true,
            deep: true,
            handler(newValue) {
                this.getWidgetTitle(newValue);
            }
        },

        widgetsDrawingContentData: {
            //immediate: true,
            deep: true,
            handler(newValue) {
                let thisWidgetContent = this.getThisWidgetDrawingData(newValue);
                if (thisWidgetContent) {
                    let thisWidget = this.getThisWidget();
                    let reference = (thisWidget && thisWidget.widgetType && thisWidget.widgetType.reference) || null;
                    if ((reference  === "NUMBER_OF_SEARCH") && thisWidgetContent.drawingData) {
                        this.widgetContentData = thisWidgetContent.drawingData.totalNumber;
                        this.widgetDetails = thisWidgetContent.drawingData.details;
                        this.widgetTimeWindow = thisWidget.queryParams.timeWindow;
                    } else {
                        if (thisWidgetContent.drawingData) {
                            this.widgetContentData = thisWidgetContent.drawingData;
                        }
                    }
                } else {
                    this.getWidgetContent(this.widgetId);
                }
            }
        },

        currentDashboard: {
            immediate: true,
            deep: true,
            handler(newValue) {
                this.refreshInterval = newValue ? parseInt(newValue.refreshInterval) || null : null;
            }
        },

        refreshInterval: {
            immediate: true,
            handler(newValue) {
                console.log("Component(widgetCounter)::watch(refreshInterval) handle with :", newValue);
                this.scheduleRefresh();
            }
        },

        widgetsLoadingState: {
            //deep: true,
            immediate: true,
            handler(newValue) {
                if (newValue.hasOwnProperty(this.widgetId)) {
                    this.isOngoingFetch = newValue[this.widgetId] ? true : false;
                } else if (this.refreshSchedulerId) {
                    // cancel scheduler
                }
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters([
            "currentDashboard",
            "currentDashboardWidgets",
            "widgetsDrawingContentData",
            "widgetsLoadingState",
            "isDarkMode"
        ])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["showDeleteModal", "getWidgetContent", "setWidgetToDetail", "setWidgetToEdit"]),

        onEditWidget: function() {
            this.setWidgetToEdit(this.widgetId);
        },

        onDeleteWidget: function () {
            this.showDeleteModal({ textContent: i18n.t("analytics_deleteWidgetConfirmText", { widgetName: this.widgetTitle }), actionName: "DELETE_WIDGET", params: { siteId: this.$route.params.siteId, dashboardId: this.$route.params.dashboardId, widgetId: this.widgetId } });
        },

        getThisWidget: function(listWidgets) {
            return (listWidgets || this.currentDashboardWidgets).filter(
                function(widgetObject) {
                    return widgetObject.id === this.widgetId;
                },
                this
            )[0];
        },

        getThisWidgetDrawingData: function(data) {
            return (data || this.widgetsDrawingContentData).filter(function(
                widgetContent
            ) {
                return widgetContent.widgetId === this.widgetId;
            },
            this)[0];
        },

        scheduleRefresh: function() {
            if (this.refreshSchedulerId) {
                clearInterval(this.refreshSchedulerId);
            }
            this.smartFetchData();
            if (parseInt(this.refreshInterval)) {
                let self = this;
                this.refreshSchedulerId = setInterval(function() {
                    console.log("Component(widgetCounter)::scheduleRefresh() -> widget " + self.widgetId + " setInterval called at " + self.refreshInterval + " seconds");
                    self.smartFetchData();
                }, parseInt(this.refreshInterval) * 1000);
            }
        },

        smartFetchData() {
            if (!this.isOngoingFetch) {
                this.getWidgetContent(this.widgetId);
            }
        },

        displayDetails() {
            this.setWidgetToDetail(this.widgetId);
        },

        formatWidgetTitle(thisWidget) {
            let isDefaultName = thisWidget.widgetType.defaultNameKey === thisWidget.customNameToDisplay;
            this.widgetTitle = isDefaultName ? i18n.t(thisWidget.customNameToDisplay) : thisWidget.customNameToDisplay;
        },

        getWidgetTitle(newValue) {
            const thisWidget = this.getThisWidget(newValue);
            if (thisWidget) {
                this.formatWidgetTitle(thisWidget);
                this.widgetDesc = thisWidget.widgetType.defaultNameKey + "_desc";
                if (thisWidget.widgetType && ["NUMBER_OF_SEARCH"].includes(thisWidget.widgetType.reference)) {
                    this.hasDetails = true;
                    this.isEditable = true;
                }
            }
        }
    },
    components: {},
    filters: {}
};
</script>

<style scoped>
.generic-widget-counterxx {
    font-size: 100px;
}
.wg-counter-title {
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 0;
    color: #6c7293;
}
.wg-counter-desc {
    display: inline-block;
    margin-top: 0.2rem;
    color: #a7abc3;
}
.wg-counter-head {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-right: 5px;
}
.wg-inner-counter {
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    justify-content: space-between;
}
</style>
