<template>
    <div class="modal fade show" id="widgetModalComponent" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-modal="true" style="display: none">
        <div class="modal-dialog modal-lg" role="document">

            <div class="modal-content" style="height: 610px">

                <div class="modal-header">
                    <h5 class="modal-title">
                        {{ $t(creationMode ? ("analytics_createWidget_Step_" + currentStep) : "analytics_editWidgetTitle") }}
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="forceCloseModal"></button>
                </div>

                <div class="modal-body" style="overflow-y: auto;">

                    <div v-show="creationMode && currentStep === 1" class="tab-content">
                        <div class="tab-pane active" aria-expanded="true">
                            <div class="kt-widget5">
                                <div v-for="widgetType in this.sortedWidgetTypesList" :key="widgetType.id" class="kt-widget5__item">
                                    <div class="kt-widget5__content">
                                        <div class="kt-widget5__pic">
                                            <img class="kt-widget7__img widget_example_img" :src="'/assets/analytics/widget_'+ widgetType.defaultNameKey + '.PNG'" :alt="widgetType.reference">
                                        </div>
                                        <div class="kt-widget5__section" style="width: 365px;">
                                            <a class="kt-widget5__title">{{$t(widgetType.defaultNameKey)}}</a>
                                            <p class="kt-widget5__desc" style=" margin-top: 5px; margin-bottom: 5px; ">{{$t(widgetType.defaultNameKey + "_desclong")}}</p>
                                            <div class="kt-widget5__info">
                                                <span>{{ $t("analytics_displayType") }} </span>
                                                <span class="kt-font-info">{{$t("analytics_widgetDisplayType_" + widgetType.displayType)}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="kt-widget5__content">
                                        <div class="kt-widget5__stats" style="padding-right: 1rem;">
                                            <a href="#" v-if="!alreadyAddedWidgetTypesRef.includes(widgetType.reference) && selectedWidgetTypeId !== widgetType.id" @click="handleSelectWidgetType(widgetType)" class="btn btn-sm btn-outline-brand btn-bold">
                                                <i class="fa fa-hand-pointer"></i>
                                                {{ $t("analytics_createWidget") }}
                                            </a>
                                            <a href="#" v-if="selectedWidgetTypeId == widgetType.id" class="btn btn-sm btn-label-success btn-bold">
                                                <i class="fa fa-check"></i>
                                                {{ $t("analytics_widgetSelected") }}
                                            </a>
                                            <a href="#" v-if="alreadyAddedWidgetTypesRef.includes(widgetType.reference)" class="btn btn-sm btn-label-success btn-bold notAllowedButton" style="width: 135px; cursor:not-allowed !important;background-color: rgba(150, 150, 150, 0.1);color: #606362;">
                                                {{ $t("analytics_alreadyAdded") }}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-show="editMode || (creationMode && currentStep === 2)">
                        <div class="kt-form kt-portlet__body" style=" margin: 5px 20px; ">

                            <div class="row" v-if="showWidgetName">
                                <div class="col-md-12 paddingLAR0 form-group validated">
                                    <label for="addWidget_CustomNameInput">{{ $t("analytics_widgetName") }} *</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="la la-pencil"></i></span>
                                        </div>
                                        <input v-model="widgetName" type="text" class="form-control" id="addWidget_CustomNameInput" :placeholder="'Widget Name'" maxlength="30" minlength="3" />
                                    </div>
                                    <div v-if="!widgetName" class="invalid-feedback">
                                        {{ $t("error_fieldIsRequired") }}
                                    </div>
                                    <div v-else-if="widgetName.length < 3" class="invalid-feedback">
                                        {{ $t("error_minLengthMsg", [3]) }}
                                    </div>
                                    <div v-else-if="widgetName.length > 30" class="invalid-feedback">
                                        {{ $t("error_maxLengthMsg", [30]) }}
                                    </div>
                                    <span class="form-text text-muted">{{ $t("analytics_widgetNameDesc") }}</span>
                                </div>
                            </div>

                            <div class="row">

                                <div v-if="formFields.includes('assetCategoryPeople')" class="form-group validated col-md-6 paddingL0">
                                    <label for="addWidget_assetCategorySelect">{{ $t("analytics_selectAssetCategory")}}</label>
                                    <select class="form-control" id="addWidget_assetCategorySelect" v-model="assetCategoryPeople" @change="onChangeForm('assetCategoryPeople')">
                                        <option disabled :value="null"> {{ $t("analytics_selectOne") }} </option>
                                        <option v-for="category of vPeopleCategories" :key="category.id" :value="category.name">{{ category.isDefault ? $t(category.name) : category.name }}</option>
                                    </select>
                                    <div v-if="!assetCategoryPeople" class="invalid-feedback">
                                        {{ $t("error_fieldIsRequired") }}
                                    </div>
                                </div>

                                <div v-if="formFields.includes('assetCategoriesFullList')" class="form-group validated col-md-6 paddingL0">
                                    <label for="addWidget_assetCategoriesFullListSelect">{{ $t("analytics_selectAssetCategory")}}</label>
                                    <select class="form-control" id="addWidget_assetCategoriesFullListSelect" v-model="assetCategoryHeatmap" @change="onChangeForm('assetCategoriesFullList')">
                                        <option disabled :value="null"> {{ $t("analytics_selectOne") }} </option>
                                        <option value="all" v-if="formFields.includes('heatmapTimeWindow')"> {{ $t("analytics_selectAssetCategory_all") }} </option>
                                        <option v-for="categoryName in fullCategoriesNamesList" :key="categoryName" :value="categoryName"> {{ $t(categoryName) }} </option>
                                    </select>
                                    <div v-if="!assetCategoryHeatmap" class="invalid-feedback">
                                        {{ $t("error_fieldIsRequired") }}
                                    </div>
                                </div>

                                <div v-if="formFields.includes('densityTimeWindow')" class="form-group validated" :class="{'col-md-6 paddingL0': formFields.includes('assetCategoryPeople'), 'col-md-12 paddingLAR0': !formFields.includes('assetCategoryPeople')}">
                                    <label for="addWidget_densityTimeWindowSelect">{{ $t("analytics_selectDensityTimeWindow")}}</label>
                                    <select class="form-control" id="addWidget_densityTimeWindowSelect" v-model="densityTimeWindow" @change="onChangeForm('densityTimeWindow')">
                                        <option disabled :value="null"> {{ $t("analytics_selectOne") }} </option>
                                        <option value="week"> {{ $t("analytics_selectDensityTW_week") }} </option>
                                        <option value="day"> {{ $t("analytics_selectDensityTW_day") }} </option>
                                        <option value="daytime"> {{ $t("analytics_selectDensityTW_daytime") }}</option>
                                        <option value="hour"> {{ $t("analytics_selectDensityTW_hour") }}</option>
                                    </select>
                                    <div v-if="!densityTimeWindow" class="invalid-feedback">
                                        {{ $t("error_fieldIsRequired") }}
                                    </div>
                                </div>

                                <div v-if="formFields.includes('heatmapTimeWindow')" class="form-group validated" :class="{'col-md-6 paddingL0': formFields.includes('assetCategoriesFullList'), 'col-md-12 paddingLAR0': !formFields.includes('assetCategoriesFullList')}">
                                    <label for="addWidget_heatmapTimeWindowSelect">{{ $t("analytics_selectHeatmapTimeWindow")}}</label>
                                    <select class="form-control" id="addWidget_heatmapTimeWindowSelect" v-model="heatmapTimeWindow" @change="onChangeForm('heatmapTimeWindow')">
                                        <option disabled :value="null"> {{ $t("analytics_selectOne") }} </option>
                                        <option value="5"> {{ $tc("analytics_selectHeatmapTW_minutes", 5) }} </option>
                                        <option value="10"> {{ $tc("analytics_selectHeatmapTW_minutes", 10) }} </option>
                                        <option value="15"> {{ $tc("analytics_selectHeatmapTW_minutes", 15) }} </option>
                                        <option value="30"> {{ $tc("analytics_selectHeatmapTW_minutes", 30) }}</option>
                                        <option value="60"> {{ $tc("analytics_selectHeatmapTW_hours", 1) }}</option>
                                        <option value="120"> {{ $tc("analytics_selectHeatmapTW_hours", 2) }}</option>
                                    </select>
                                    <div v-if="!heatmapTimeWindow" class="invalid-feedback">
                                        {{ $t("error_fieldIsRequired") }}
                                    </div>
                                </div>

                                <div v-if="formFields.includes('searchDetailsTimeWindow')" class="form-group validated" :class="{'col-md-6 paddingL0': formFields.includes('assetCategoryPeople'), 'col-md-12 paddingLAR0': !formFields.includes('assetCategoryPeople')}">
                                    <label for="addWidget_searchTimeWindowSelect">{{ $t("analytics_selectsearchTimeWindow")}}</label>
                                    <select class="form-control" id="addWidget_searchTimeWindowSelect" v-model="searchDetailsTimeWindow" @change="onChangeForm('searchDetailsTimeWindow')">
                                        <option disabled :value="null"> {{ $t("analytics_selectOne") }} </option>
                                        <option value="hour"> {{ $tc("analytics_searchTW_hours", 1)}} </option>
                                        <option value="12H"> {{ $tc("analytics_searchTW_hours", 12) }} </option>
                                        <option value="24H"> {{ $tc("analytics_searchTW_hours", 24) }} </option>
                                        <option value="5days"> {{ $tc("analytics_searchTW_days", 5) }}</option>
                                        <option value="15days"> {{ $tc("analytics_searchTW_days", 15) }}</option>
                                    </select>
                                    <div v-if="!searchDetailsTimeWindow" class="invalid-feedback">
                                        {{ $t("error_fieldIsRequired") }}
                                    </div>
                                </div>

                                <div v-if="formFields.includes('searchTimeWindowForROI')" class="form-group validated" :class="{'col-md-6 paddingL0': formFields.includes('assetCategoryPeople'), 'col-md-12 paddingLAR0': !formFields.includes('assetCategoryPeople')}">
                                    <label for="addWidget_searchTimeWindowSelect">{{ $t("analytics_selectsearchTimeWindow")}}</label>
                                    <select class="form-control" id="addWidget_searchTimeWindowSelect" v-model="searchTimeWindowForROI" @change="onChangeForm('searchTimeWindowForROI')">
                                        <option disabled :value="null"> {{ $t("analytics_selectOne") }} </option>
                                        <option value="hour"> {{ $tc("analytics_searchTW_hours")}} </option>
                                        <option value="12H"> {{ $tc("analytics_searchTW_hours", 12) }} </option>
                                        <option value="24H"> {{ $tc("analytics_searchTW_hours", 24) }} </option>
                                        <option value="5days"> {{ $tc("analytics_searchTW_days", 5) }}</option>
                                        <option value="15days"> {{ $tc("analytics_searchTW_days", 15) }}</option>
                                    </select>
                                    <div v-if="!searchTimeWindowForROI" class="invalid-feedback">
                                        {{ $t("error_fieldIsRequired") }}
                                    </div>
                                </div>
                            </div>

                            <div v-if="formFields.includes('ROIavgHourSalary')" class="row">
                                <div class="col-md-12 paddingLAR0">
                                    <div class="form-group validated">
                                        <label for="addWidget_ROIsearchMeanTime">{{ $t("analytics_ROI_searchMinTime") }}*</label>
                                        <div class="input-group">
                                            <input v-model="ROIsearchMeanTime" type="number" value="10" min="1" class="form-control" id="addWidget_ROIsearchMeanTime"/>
                                        </div>
                                        <div v-if="!ROIsearchMeanTime" class="invalid-feedback">
                                            {{ $t("error_fieldIsRequired") }}
                                        </div>
                                        <div v-else-if="!(parseInt(ROIsearchMeanTime) > 0)" class="invalid-feedback">
                                            Error!
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="formFields.includes('ROIavgHourSalary')" class="row">

                                <div class="col-md-5 paddingL0">
                                    <div class="form-group validated">
                                        <label for="addWidget_ROIavgHourSalary">{{ $t("analytics_ROI_hourSalary") }}*</label>

                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">{{ROIcurrency}}</span>
                                            </div>
                                            <input type="number" id="addWidget_ROIavgHourSalary" class="form-control" step="0.1" v-model="ROIavgHourSalary" data-number-to-fixed="2" data-number-stepfactor="1" >
                                        </div>
                                        <div v-if="!ROIavgHourSalary" class="invalid-feedback">
                                            {{ $t("error_fieldIsRequired") }}
                                        </div>
                                        <div v-else-if="!(ROIavgHourSalary > 0)" class="invalid-feedback">
                                            Error!
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2 paddingL0">
                                    <div class="form-group validated">
                                        <label for="addWidget_ROIcurrency">{{ $t("analytics_ROI_currency") }}*</label>
                                        <select class="form-control" id="addWidget_ROIcurrency" v-model="ROIcurrency" @change="onChangeForm('ROIcurrency')">
                                            <option selected value="$"> USD </option>
                                            <option value="€"> EUR </option>
                                            <option value="£"> GBP </option>
                                        </select>
                                        <div v-if="!ROIcurrency" class="invalid-feedback">
                                            {{ $t("error_fieldIsRequired") }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="formFields.includes('areaTypeAndId')" class="row">
                                <div class="form-group validated col-md-6 paddingL0">
                                    <label for="addWidget_areaTypeSelect">{{ $t("analytics_selectAreaType") }}</label>
                                    <select class="form-control" id="addWidget_areaTypeSelect" v-model="areaType" @change="onChangeForm('areaType')">
                                        <option disabled :value="null"> {{ $t("analytics_selectOne") }} </option>
                                        <option value="site" :disabled="!site"> {{ $t("analytics_areaType_site") }} </option>
                                        <option value="building" :disabled="!buildings"> {{ $t("analytics_areaType_building") }} </option>
                                        <option value="floor" :disabled="!floors"> {{ $t("analytics_areaType_floor") }}</option>
                                        <option value="geofence" v-if="geofences"> {{ $t("analytics_areaType_geofence") }}</option>
                                    </select>
                                    <div v-if="!areaType" class="invalid-feedback">
                                        {{ $t("error_fieldIsRequired") }}
                                    </div>
                                </div>

                                <div class="form-group validated col-md-6 paddingL0" v-if="areaType">
                                    <label for="addWidget_areaIdSelect">{{ $t("analytics_selectAreaId") }}</label>

                                    <select v-if="areaType == 'site'" class="form-control" id="addWidget_areaIdSelect" v-model="areaId" @change="onChangeForm('areaId')">
                                        <option :value="site.id" selected> {{site.name}}</option>
                                    </select>

                                    <select v-if="areaType == 'building'" class="form-control" id="addWidget_areaIdSelect" v-model="areaId" @change="onChangeForm('areaId')">
                                        <option disabled :value="null"> {{ $t("analytics_selectOne") }} </option>
                                        <option v-for="(name, id) in buildings" :key="id" :value="id"> {{name}}</option>
                                    </select>

                                    <select v-if="areaType == 'floor'" class="form-control" id="addWidget_areaIdSelect" v-model="areaId" @change="onChangeForm('areaId')">
                                        <option disabled :value="null"> {{ $t("analytics_selectOne") }} </option>
                                        <option v-for="(name, id) in floors" :key="id" :value="id"> {{name}}</option>
                                    </select>
                                    <select v-if="areaType == 'geofence'" class="form-control" id="addWidget_areaIdSelect" v-model="areaId" @change="onChangeForm('areaId')">
                                        <option disabled :value="null"> {{ $t("analytics_selectOne") }} </option>
                                        <option v-for="(name, id) in geofences" :key="id" :value="id"> {{name}}</option>
                                    </select>
                                    <div v-if="!areaId" class="invalid-feedback">
                                        {{ $t("error_fieldIsRequired") }}
                                    </div>
                                </div>

                            </div>

                            <div class="row form-group validated" v-if="formFields.includes('PeopleFlow') && !noPeopleAssetFound">
                                <label for="addWidget_peopleFlowTW">{{ $t("analytics_select_PF_TimeWindow") }}</label>
                                <select class="form-control" id="addWidget_peopleFlowTW" v-model="peopleFlowTW" @change="onChangeForm('PeopleFlowTW')">
                                    <option disabled :value="null"> {{ $t("analytics_selectOne") }} </option>
                                    <option value="1h"> {{ $tc("analytics_nbr_hours") }} </option>
                                    <option value="2h"> {{ $tc("analytics_nbr_hours", 2) }} </option>
                                    <option value="4h"> {{ $tc("analytics_nbr_hours", 4) }} </option>
                                    <option value="8h"> {{ $tc("analytics_nbr_hours", 8) }} </option>
                                    <option value="12h"> {{ $tc("analytics_nbr_hours", 12) }} </option>
                                </select>
                                <div v-if="!peopleFlowTW" class="invalid-feedback">
                                    {{ $t("error_fieldIsRequired") }}
                                </div>
                            </div>

                            <div class="row form-group validated" v-show="formFields.includes('PeopleFlow') && !noPeopleAssetFound">
                                <span> {{ $t("analytics_selectPeople") }}</span>
                                <div class="kt-radio-list col-md-12" style="margin-left: 10px; margin-top: -2px;">
                                    <div class="row" style="margin-top: 20px;">
                                        <label class="kt-radio kt-radio--bold kt-radio--brand">
                                            <input id="peopleFlowWidgetAllPeople" type="radio" name="peopleFlowChoice" v-model="vPeopleFlow" value="all" checked /> {{ $t("analytics_allPeople") }}
                                            <span></span>
                                        </label>
                                    </div>

                                    <div class="row" style="margin-top: 10px;">
                                        <label class="kt-radio kt-radio--bold kt-radio--brand" style="margin-right: 7px; margin-top: 7px;">
                                            <input id="peopleFlowWidgetOnlyCategory" type="radio" name="peopleFlowChoice" v-model="vPeopleFlow" value="category" /> {{ $t("analytics_onlyCategory") }}
                                            <span></span>
                                        </label>
                                        <select id="peopleFlowWidgetOnlyCategorySelect2" class="form-control kt-select2" disabled>
                                            <!--option></option-->
                                        </select>
                                        <div v-if="!isCorrectPeopleCategorySelected" class="invalid-feedback">
                                            {{ $t("error_fieldIsRequired") }}
                                        </div>
                                    </div>

                                    <div class="row" style="margin-top: 10px;">
                                        <label class="kt-radio kt-radio--bold kt-radio--brand" style="margin-right: 7px; margin-top: 7px;">
                                            <input id="peopleFlowWidgetOnlyAsset" type="radio" name="peopleFlowChoice" v-model="vPeopleFlow" value="asset" /> {{ $t("analytics_onlyAsset") }}
                                            <span></span>
                                        </label>
                                        <select id="peopleFlowWidgetOnlyAssetSelect2" class="form-control kt-select2" disabled>
                                            <!--option></option-->
                                        </select>
                                        <div v-if="!isCorrectPeopleAssetSelected" class="invalid-feedback">
                                            {{ $t("error_fieldIsRequired") }}
                                        </div>
                                    </div>
                                </div>
                                <div v-if="!vPeopleFlow" class="invalid-feedback">
                                    {{ $t("error_fieldIsRequired") }}
                                </div>
                            </div>

                            <div class="row form-group validated" v-if="formFields.includes('assetThresholdInGeofence') && geofences">
                                <label for="addWidget_assetThresoldGeofence">{{ $t("analytics_select_thresholdGeofence") }}</label>
                                <select class="form-control" id="addWidget_assetThresoldGeofence" v-model="thresholdGeofence">
                                    <option disabled :value="null"> {{ $t("analytics_selectOne") }} </option>
                                    <option v-for="(name, id) in geofences" :key="id" :value="id"> {{ name }}</option>
                                </select>
                                <div v-if="!thresholdGeofence" class="invalid-feedback">
                                    {{ $t("error_fieldIsRequired") }}
                                </div>
                                <span class="form-text text-muted">{{ $t("analytics_thresholdWidgetGefoenceDesc") }}</span>
                            </div>

                            <div class="row form-group validated" v-if="formFields.includes('assetThresholdInGeofence') && geofences">
                                <label for="addWidget_assetThresoldMax">{{ $t("analytics_select_thresholdMaxAssets") }}</label>
                                <input v-model="thresholdNumber" id="addWidget_assetThresoldMax" type="number" min=1 class="form-control" :placeholder='$t("analytics_select_thresholdMaxAssetPlaceholder")' />
                                <div v-if="!$v.thresholdNumber.required" class="invalid-feedback">
                                    {{ $t("error_fieldIsRequired") }}
                                </div>
                                <div v-if="!$v.thresholdNumber.integer" class="invalid-feedback">
                                    {{ $t("error_invalidThresholdNumberType") }}
                                </div>
                                <div v-if="!$v.thresholdNumber.between" class="invalid-feedback">
                                    {{ $t("error_betweenValueMsg", [$v.thresholdNumber.$params.between.min, $v.thresholdNumber.$params.between.max]) }}
                                </div>
                            </div>

                            <app-emptylist
                                :targetObjectName="formFields.includes('assetThresholdInGeofence') ? 'WIDGETTHRESHOLD_GEOFENCE' : 'WIDGETPEOPLEFLOW_ASSET'"
                                :mainTextKey="formFields.includes('assetThresholdInGeofence')  ? 'geofence_notFound' : 'asset_notFound'"
                                :subTextKey="formFields.includes('assetThresholdInGeofence') ? 'error_siteGeofenceRequiredForThreshold' : 'error_sitePeopleAssetRequiredForPeopleFlow'"
                                :imgName="formFields.includes('assetThresholdInGeofence') ? 'empty_geofence.svg' : 'empty_asset.svg'"
                                v-bind:isDisplayed="(formFields.includes('assetThresholdInGeofence') && !geofences) || (formFields.includes('PeopleFlow') && noPeopleAssetFound)">
                            </app-emptylist>

                        </div>
                    </div>

                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="cancelAddWidget">
                        {{ $t("common_cancel") }}
                    </button>
                    <button class="btn btn-outline-dark" @click="backToStep1" v-if="creationMode && currentStep === 2">
                        <i class="fa fa-angle-left" style=" margin-top: -3px; "></i>
                        {{ $t("analytics_createWidget_backToStep1") }}
                    </button>
                    <button type="button" @click="onSubmit" v-bind:disabled="!this.formIsValid() || inProgressSpinnerActive || ($v.$invalid && formFields.includes('assetThresholdInGeofence'))" :class="{ 'btn btn-brand kt-margin-r-5 kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': inProgressSpinnerActive, 'btn btn-brand kt-margin-r-5': !inProgressSpinnerActive }" v-if="(creationMode && currentStep === 2 && showWidgetName) || editMode">
                        {{ $t(editMode ? "analytics_update" : "analytics_create") }}
                    </button>
                    <button type="button" @click="addWidgetGoStep2" v-bind:disabled="!selectedWidgetTypeId" :class="{ 'btn btn-brand kt-margin-r-5 kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': false, 'btn btn-brand kt-margin-r-5': true }" v-if="creationMode && currentStep === 1">
                        {{ $t("analytics_createWidget_Next") }}
                        <i class="fa fa-angle-right" style=" margin-left: 5px; margin-top: -1px; margin-right: -5px; "></i>
                    </button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required, integer, between } from "vuelidate/lib/validators";
import commonVueHelper from "../../helpers/commonVueHelper";
import EmptyList from "./../utils/emptylist.vue";
import i18n from "../../i18n";

export default {
    data() {
        return {
            showWidgetName: true, // Show the name of widget name in the case where no geofence found we can add it
            errorDisplayed: false,
            payload: {},
            creationMode: false,
            editMode: false,
            currentStep: 1,
            siteId: this.$route.params.siteId,

            sortedWidgetTypesList: [],
            alreadyAddedWidgetTypesRef: [],

            widgetTypeObj: null,
            selectedWidgetTypeId: null,
            widgetObjectToEdit: null,
            inProgressSpinnerActive: false,

            widgetTypeBarCharts: [],
            widgetTypePieCharts: [],
            widgetTypeCounters: [],
            widgetTypeThresholds: [],
            widgetTypeHeatmap: [],
            widgetTypeROI: [],
            widgetTypePatientFlow: [],

            site: null,
            buildings: null,
            floors: null,
            geofences: null,
            sitePatients: [],

            widgetName: "",
            areaType: null,
            areaId: null,
            densityTimeWindow: null,
            locHistoryTimeWindow: null,
            heatmapTimeWindow: null,
            searchTimeWindowForROI: null,
            searchDetailsTimeWindow: null,
            assetCategoryPeople: null,
            assetCategoryHeatmap: null,

            ROIcurrency: null,
            ROIsearchMeanTime: null,
            ROIavgHourSalary: null,

            vPeopleFlow: "all",
            vPeopleCategories: [],
            vPeopleAssets: [],
            vPeopleCategoriesUserSelect: [],
            vPeopleAssetsUserSelect: [],
            noPeopleAssetFound: false,
            isCorrectPeopleCategorySelected: true,
            isCorrectPeopleAssetSelected: true,
            peopleFlowTW: null,
            peopleFlowPeopleIds: [],

            fullCategoriesNamesList: [],
            formFields: [],

            thresholdGeofence: null,
            thresholdNumber: 1
        };
    },
    created: function() {
        console.log("Component(widgetModal)::created() - called");
        // Get all widgets types, to be used later by the Add widget modal (catalog)
        this.getAllWidgetTypes();
        // Get geofences
        this.getSiteGeofences(this.siteId);
    },
    mounted: function() {
        console.log("Component(widgetModal)::mounted() - Init metronic layout");
        KTLayout.init();
        $('[data-toggle="kt-popover"]').popover({ trigger: "hover" });
        // Get all site asset categories
        this.getSiteAssetCategories(this.$route.params.siteId);
        // Get All site assets
        this.getSiteAssets(this.$route.params.siteId);
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
        thresholdNumber: {
            integer,
            required,
            between: between(1, 5000)
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        widgetTypesList: {
            immediate: true,
            deep: true,
            handler: function(newValue) {
                this.widgetTypeBarCharts = [];
                this.widgetTypePieCharts = [];
                this.widgetTypeCounters = [];
                this.widgetTypeHeatmap = [];
                this.widgetTypeROI = [];
                this.widgetTypePatientFlow = [];
                //reset the modal's parameters
                //console.log("Watcher of 'widgetTypesList' got fired!");
                this.widgetTypesList.forEach(function(widgetType) {
                    let displayType = (widgetType ? widgetType.displayType : "");
                    switch (widgetType.displayType) {
                        case "Pie_Chart":
                            this.widgetTypePieCharts.push(widgetType);
                            break;
                        case "Bar_Chart":
                            this.widgetTypeBarCharts.push(widgetType);
                            break;
                        case "Number":
                            this.widgetTypeCounters.push(widgetType);
                            break;
                        case "Threshold":
                            this.widgetTypeThresholds.push(widgetType);
                            break;
                        case "Heatmap":
                            this.widgetTypeHeatmap.push(widgetType);
                            break
                        case "ROI":
                            this.widgetTypeROI.push(widgetType);
                            break;
                        case "People_Flow":
                            this.widgetTypePatientFlow.push(widgetType);
                            break;
                        default:
                            console.log("Component(widgetModal)::widgetTypesList - handler - Unknow display type '" + displayType + "' of widget type", widgetType);
                            break;
                    }
                }, this);
                this.sortWidgetTypesList();
            }
        },

        widgetToEdit: {
            deep: true,
            handler(newValue) {
                if (newValue && newValue.widgetType) {
                    this.toggleModalMode();
                } else {
                    this.forceCloseModal();
                    return;
                }
            }
        },

        widgetCreationMode: function () {
            this.toggleModalMode();
        },

        widgetCreationOrUpdateInProgess: {
            immediate: true,
            handler: function(newValue) {
                //console.log ("Watcher 'widgetCreationOrUpdateInProgess' got fired with new value: " + newValue);
                this.inProgressSpinnerActive = newValue;
            }
        },
        // Watch siteCategories
        siteAssetCategories: {
            immediate: true,
            deep: true,
            handler(newValue) {
                if (newValue) {
                    this.fullCategoriesNamesList = newValue.map(function(category) {
                        return category.name
                    });
                    // Select only people category
                    let self = this;
                    this.vPeopleCategories = _.filter(newValue, function(category) {
                        return category.isPeopleCategory;
                    });
                    this.initPeopleAssetCategorySelect2();
                } else {
                    this.fullCategoriesNamesList = [];
                    this.vPeopleCategories = [];
                }
            }
        },

        currentSite: {
            immediate: true,
            handler(newValue) {
                if (newValue && newValue.id) {
                    this.site = {
                        id: newValue.id,
                        name: newValue.name
                    };
                } else {
                    this.site = null;
                }
                //console.log("site", this.site);
            }
        },

        siteBuildings: {
            immediate: true,
            handler(newValue) {
                if (newValue && newValue.length) {
                    this.buildings = {};
                    this.floors = {};
                    for (let i = 0; i < newValue.length; i++) {
                        let building = newValue[i];
                        this.buildings[building.id] = building.name;
                        if (building.floors) {
                            for (let j = 0; j < building.floors.length; j++) {
                                let floor = building.floors[j];
                                this.floors[floor.id] =
                                    building.name + " / " + floor.name;
                            }
                        }
                    }
                    if (!Object.keys(this.floors).length) {
                        this.floors = null;
                    }
                    if (!Object.keys(this.buildings).length) {
                        this.buildings = null;
                    }
                } else {
                    this.floors = null;
                    this.buildings = null;
                }
                //console.log("buildings", this.buildings);
                //console.log("floors", this.floors);
            }
        },

        siteGeofences: {
            immediate: true,
            handler(newValue) {
                if (newValue && newValue.length) {
                    this.geofences = {};
                    for (let i = 0; i < newValue.length; i++) {
                        let geofence = newValue[i];
                        this.geofences[geofence.id] =
                            geofence.building.name +
                            " / " +
                            geofence.floor.name +
                            " / " +
                            geofence.name;
                    }
                } else {
                    this.geofences = null;
                }
            }
        },

        siteAssets: {
            immediate: true,
            deep: true,
            handler(newValue) {
                if (Array.isArray(newValue) && newValue.length > 0) {
                    this.sitePatients = newValue.filter(function (asset) {
                        let isPatient = asset.assetcategory && asset.assetcategory.name === 'ASSET_C_PATIENTS';
                        let isWearingTag = asset.tag && asset.tag.macAddress ? true : false;
                        return isPatient && isWearingTag;
                    }).reduce(function (cumul, patient) {
                        cumul[patient.id] = patient.name;
                        return cumul;
                    }, {});
                    // Select only people category
                    let self = this;
                    this.vPeopleAssets = _.filter(newValue, function(asset) {
                        if (asset && asset.assetcategory && asset.assetcategory.name && asset.assetcategory.isPeopleCategory && asset.tag) {
                            return asset;
                        }
                    });

                    if (this.vPeopleAssets && this.vPeopleAssets.length > 0) {
                        this.noPeopleAssetFound = false;
                    }
                } else {
                    this.sitePatients = {};
                    this.vPeopleAssets = [];
                    this.noPeopleAssetFound = true;
                }
            }
        },

        currentDashboardWidgets: {
            immediate: true,
            deep: true,
            handler: function() {
                let self =this;
                this.alreadyAddedWidgetTypesRef = this.currentDashboardWidgets.reduce(function(alreadyAddedWidgetTypes, widgetObject) {
                    if (widgetObject.widgetType && !widgetObject.widgetType.isCustomizable) {
                        alreadyAddedWidgetTypes.push(widgetObject.widgetType.reference);
                    }
                    return alreadyAddedWidgetTypes;
                }, []);
                this.sortWidgetTypesList();
            }
        },

        // Watch the change of selectPeople radio
        vPeopleFlow: function(value) {
            if (value === "all" && this.formFields.includes('PeopleFlow')) {
                // Disable the select2 of peopleAssetCategorieSelect2 and peopleAssetSelect2
                $("#peopleFlowWidgetOnlyCategorySelect2").attr("disabled", "true");
                $("#peopleFlowWidgetOnlyAssetSelect2").attr("disabled", "true");
                this.isCorrectPeopleCategorySelected = true;
                this.isCorrectPeopleAssetSelected = true;
            } else if (value === "category" && this.formFields.includes('PeopleFlow')) {
                // Enable the select2 of people asset category
                $("#peopleFlowWidgetOnlyCategorySelect2").removeAttr("disabled");
                // Disable the select2 of people asset
                $("#peopleFlowWidgetOnlyAssetSelect2").attr("disabled", "true");
                this.isCorrectPeopleAssetSelected = true;
                // Verify if select2 has choice or not
                if (this.vPeopleCategoriesUserSelect && this.vPeopleCategoriesUserSelect.length > 0) {
                    this.isCorrectPeopleCategorySelected = true;
                } else {
                    this.isCorrectPeopleCategorySelected = false;
                }
            } else if (value === "asset" && this.formFields.includes('PeopleFlow')) {
                // Enable the select2 of people asset
                $("#peopleFlowWidgetOnlyAssetSelect2").removeAttr("disabled");
                // Disable the select2 of people asset category
                $("#peopleFlowWidgetOnlyCategorySelect2").attr("disabled", "true");
                this.isCorrectPeopleCategorySelected = true;
                // Verify if select2 has choice or not
                if (this.vPeopleAssetsUserSelect && this.vPeopleAssetsUserSelect.length > 0) {
                    this.isCorrectPeopleAssetSelected = true;
                } else {
                    this.isCorrectPeopleAssetSelected = false;
                }
            } else {
                // Not normal for defense we can set the default value to all
                this.vPeopleFlow = "all";
            }
        },

        formFields: function() {
            // In people flow widget case, the asset people is required
            if (this.formFields.includes("PeopleFlow") && this.vPeopleAssets && this.vPeopleAssets.length === 0) {
                this.noPeopleAssetFound = true;
                this.showWidgetName = false;
            } else if (this.formFields.includes("assetThresholdInGeofence") && !this.geofences) {
                // Geofence is required for threshold widgets
                this.showWidgetName = false;
            } else {
                this.showWidgetName = true;
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters([
            "widgetTypesList",
            "widgetCreationOrUpdateInProgess",
            "currentSite",
            "siteGeofences",
            "siteBuildings",
            "currentDashboardWidgets",
            "siteAssetCategories",
            "widgetUpdateInProgess",
            "widgetToEdit",
            "widgetCreationMode",
            "siteAssets"
        ])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["createWidget", "resetAddWidgetModalDisplay", "updateWidget", "getSiteGeofences", "unsetWidgetToEdit", "setWidgetCreationModeTo", "getSiteAssetCategories", "getAllWidgetTypes", "getSiteAssets"]),

        toggleModalMode() {
            if (this.widgetToEdit && this.widgetToEdit.widgetType) {
                this.creationMode = false;
                this.editMode = true;
                this.openModal();
            } else if (this.widgetCreationMode) {
                this.creationMode = true;
                this.editMode = false;
                this.openModal();
            } else {
                this.creationMode = false;
                this.editMode = false;
                this.forceCloseModal();
            }
        },

        resetModalParams() {
            this.payload = {};
            this.currentStep = 1;
            this.errorDisplayed = false;
            this.widgetTypeObj = null;
            this.widgetObjectToEdit = null;
            this.selectedWidgetTypeId = null;
            this.inProgressSpinnerActive = false;

            this.widgetName = "";
            this.areaType = null;
            this.areaId = null;
            this.assetCategoryPeople = null;
            this.densityTimeWindow = null;
            this.heatmapTimeWindow = null;
            this.assetCategoryHeatmap = null;
            this.locHistoryTimeWindow = null;
            this.searchDetailsTimeWindow = null;
            this.searchTimeWindowForROI = null;

            this.formFields = [];
            this.ROIcurrency = null;
            this.ROIsearchMeanTime = null;
            this.ROIavgHourSalary = null;
            this.peopleFlowTW = null;
            this.peopleFlowPeopleIds = null;
        },
        openModal() {
            //init params
            this.resetModalParams();
            if (this.editMode) {
                this.widgetObjectToEdit = this.widgetToEdit;
                this.widgetTypeObj = this.widgetToEdit.widgetType;
                this.initFormParams();
            }
            if (this.creationMode || this.editMode) {
                $("#widgetModalComponent").modal("show");
            }
        },

        forceCloseModal() {
            //smash params
            this.resetModalParams();
            $("#widgetModalComponent div.modal-body").scrollTop(0);
            $("#widgetModalComponent").modal("hide");
            this.setWidgetCreationModeTo(false);
            this.unsetWidgetToEdit();
        },

        onChangeForm(origin) {
            switch (origin) {
                case "areaType":
                    this.areaId =
                    this.areaType === "site" ? this.site.id : null;
                    break;
                // case "PatientsFlowAll":
                //     if (this.peopleFlowPeopleIds.includes("all")) {
                //         this.peopleFlowPeopleIds = ["all"];
                //     }
                //     break;
                case "ROIcurrency":
                    if (this.ROIcurrency == "£") {
                        this.ROIavgHourSalary = 11;
                    } else {
                        this.ROIavgHourSalary = 10;
                    }
                    break;
                default:
                    break;
            }
        },

        sortWidgetTypesList() {
            this.sortedWidgetTypesList = [
                ...this.widgetTypeCounters,
                ...this.widgetTypeThresholds,
                ...this.widgetTypePieCharts,
                ...this.widgetTypeBarCharts,
                ...this.widgetTypeHeatmap,
                ...this.widgetTypeROI,
                ...this.widgetTypePatientFlow
            ];
            let self = this;
            //display non-added yet widget types at the top of the list
            this.sortedWidgetTypesList.sort(function(wt1, wt2) {
                if (!self.alreadyAddedWidgetTypesRef.includes(wt1.reference) && self.alreadyAddedWidgetTypesRef.includes(wt2.reference)) {
                    return -1;
                }
                if (self.alreadyAddedWidgetTypesRef.includes(wt1.reference) && !self.alreadyAddedWidgetTypesRef.includes(wt2.reference)) {
                    return 1;
                }
            });
        },

        cancelAddWidget() {
            this.forceCloseModal();
        },

        assessWidgetModelSelection(widgetTypeObj) {
            if (this.alreadyAddedWidgetTypesRef.includes(widgetTypeObj.reference)) {
                return ['alreadyAddedWidgetModel']
            } else if (this.selectedWidgetTypeId == widgetTypeObj.id) {
                return ['selectedWidgetType']
            }
        },

        toggleBottomRightButtonStyle(widgetTypeObj) {
            if (this.alreadyAddedWidgetTypesRef.includes(widgetTypeObj.reference) || this.selectedWidgetTypeId == widgetTypeObj.id) {
                return ['toggleBottomRightButton']
            }
            return [];
        },

        handleSelectWidgetType(widgetTypeObj) {
            //a="{'selectedWidgetType': selectedWidgetTypeId == widgetType.id}"
            if (!this.alreadyAddedWidgetTypesRef.includes(widgetTypeObj.reference)) {
                this.widgetTypeObj = widgetTypeObj;
                this.selectedWidgetTypeId = widgetTypeObj.id;
            }
        },

        initFormParams() {
            if (!(this.widgetTypeObj && this.widgetTypeObj.reference)) {
                this.forceCloseModal();
                console.error("Component(widgetModal)::initFormParams(): no valid widgetType", this.widgetTypeObj);
                return;
            }
            if (this.editMode && !(this.widgetToEdit && this.widgetToEdit.widgetType)) {
                this.forceCloseModal();
                console.error("Component(widgetModal)::initFormParams(): edit mode set without valid widget To Edit");
                return;
            }
            let isDefaultName = this.editMode && (this.widgetTypeObj.defaultNameKey === this.widgetToEdit.customNameToDisplay);

            this.widgetName = this.editMode ? (isDefaultName ? i18n.t(this.widgetTypeObj.defaultNameKey) : this.widgetToEdit.customNameToDisplay) : "";

            if (!this.editMode && this.widgetTypeObj.defaultNameKey == "Assets_Threshold_In_Geofence") {
                this.widgetName = i18n.t("analytics_thresholdOccupancyManagement");
            }

            let queryParams = this.widgetToEdit && this.widgetToEdit.queryParams;
            let pathParams = this.widgetToEdit && this.widgetToEdit.pathParams;
            let widgetDetails = this.widgetToEdit && this.widgetToEdit.detailsInfo;
            switch (this.widgetTypeObj.reference) {
                case "AREA_DENSITY_OF_ASSETS":
                    this.formFields = ["areaTypeAndId", "densityTimeWindow"];
                    this.densityTimeWindow = (queryParams && queryParams.timeWindow) || null;
                    this.areaType = (queryParams && queryParams.areaType) || null;
                    this.areaId = (queryParams && queryParams.areaId) || null;
                    break;
                case "AREA_DENSITY_OF_PEOPLE":
                    this.formFields = ["areaTypeAndId", "densityTimeWindow", "assetCategoryPeople"];
                    this.densityTimeWindow = (queryParams && queryParams.timeWindow) || null;
                    this.areaType = (queryParams && queryParams.areaType) || null;
                    this.areaId = (queryParams && queryParams.areaId) || null;
                    this.assetCategoryPeople = (queryParams && queryParams.category) || null;
                    break;
                case "ASSETS_LOCATION_HEATMAP":
                    this.formFields = ["heatmapTimeWindow", "assetCategoriesFullList"];
                    this.heatmapTimeWindow = (queryParams && queryParams.timeWindow) || null;
                    this.assetCategoryHeatmap = (queryParams && queryParams.category) || null;
                    break;
                case "NUMBER_OF_SEARCH":
                    this.formFields = ["searchDetailsTimeWindow"];
                    this.searchDetailsTimeWindow = (queryParams && queryParams.timeWindow) || null;
                    break;
                case "RETURN_ON_INVESTMENT":
                    this.formFields = ["searchTimeWindowForROI", "ROIsearchMeanTime", "ROIavgHourSalary", "ROIcurrency"];
                    this.searchTimeWindowForROI = (queryParams && queryParams.timeWindow) || null;
                    this.ROIcurrency = (widgetDetails && widgetDetails.currency) || "$";
                    this.ROIsearchMeanTime = (widgetDetails && widgetDetails.searchMeanTime) || 15;
                    this.ROIavgHourSalary = (widgetDetails && widgetDetails.avgHourSalary) || 10;
                    break;
                case "PEOPLE_FLOW":
                    this.formFields = ["PeopleFlow"];
                    this.peopleFlowTW = (queryParams && queryParams.timeWindow) || null;
                    // Set the value of people flow when it is edit or add
                    if (this.editMode && queryParams && queryParams.peopleId) {
                        if (queryParams.peopleId === "all") {
                            this.vPeopleAssetsUserSelect = [];
                            this.initPeopleAssetSelect2();
                            this.vPeopleCategoriesUserSelect = [];
                            this.initPeopleAssetCategorySelect2();
                            this.vPeopleFlow = "all";
                        } else {
                            const peopleIds = queryParams.peopleId.split(",");
                            // Very if it is asset id else asset categories
                            if (this.vPeopleCategories && this.vPeopleCategories.length > 0) {
                                // Find the peopleIds
                                let findCategory = _.filter(this.vPeopleCategories, (category) => {
                                    if (peopleIds.includes(category.id)) {
                                        return category;
                                    }
                                });
                                // Verify if all peopleIds has found
                                if (findCategory && peopleIds.length === findCategory.length) {
                                    // Right the peopleId concern asset category
                                    this.vPeopleCategoriesUserSelect = peopleIds;
                                    this.peopleFlowPeopleIds = peopleIds;
                                    this.initPeopleAssetCategorySelect2();
                                    this.vPeopleFlow = "category";
                                    // Set to empty the asset choice
                                    this.vPeopleAssetsUserSelect = [];
                                    this.initPeopleAssetSelect2();
                                } else {
                                    // Find the peopleIds in asssets
                                    let findAssets = _.filter(this.vPeopleAssets, (asset) => {
                                        if (peopleIds.includes(asset.id)) {
                                            return asset;
                                        }
                                    });
                                    // Verify if all peopleIds has found
                                    if (findAssets && peopleIds.length === findAssets.length) {
                                        // Right the peopleId concern asset
                                        this.vPeopleAssetsUserSelect = peopleIds;
                                        this.peopleFlowPeopleIds = peopleIds;
                                        this.initPeopleAssetSelect2();
                                        this.vPeopleFlow = "asset";
                                        // Set to empty the category choice
                                        this.vPeopleCategoriesUserSelect = [];
                                        this.initPeopleAssetCategorySelect2();
                                    } else {
                                        // Defense in case when asset or category are deleted after widget creation
                                        this.vPeopleAssetsUserSelect = [];
                                        this.initPeopleAssetSelect2();
                                        this.vPeopleCategoriesUserSelect = [];
                                        this.initPeopleAssetCategorySelect2();
                                        this.vPeopleFlow = "all";
                                    }
                                }
                            } else {
                                // Defense in case when asset or category are deleted after widget creation
                                this.vPeopleAssetsUserSelect = [];
                                this.initPeopleAssetSelect2();
                                this.vPeopleCategoriesUserSelect = [];
                                this.initPeopleAssetCategorySelect2();
                                this.vPeopleFlow = "all";
                            }
                        }
                    } else {
                        // It is add mode we init by empty
                        this.peopleFlowPeopleIds = [];
                        this.vPeopleAssetsUserSelect = [];
                        this.initPeopleAssetSelect2();
                        this.vPeopleCategoriesUserSelect = [];
                        this.initPeopleAssetCategorySelect2();
                        this.vPeopleFlow = "all";
                    }
                    break;
                case "ASSETS_THESHOLD_IN_GEOFENCE":
                    this.formFields = ["assetThresholdInGeofence"];
                    // Set geofence id and threshold
                    if (pathParams && pathParams.GEOFENCE_ID && queryParams.assetThreshold) {
                        this.thresholdGeofence = pathParams.GEOFENCE_ID;
                        this.thresholdNumber = queryParams.assetThreshold;
                    } else if (this.editMode) {
                        // add defense close
                        this.forceCloseModal();
                    } else {
                        this.thresholdGeofence = null;
                        this.thresholdNumber = 1;
                    }
                    break;
                default:
                    this.formFields = [];
                    break;
            }
            $("#widgetModalComponent div.modal-body").scrollTop(0);
        },

        addWidgetGoStep2() {
            if (this.widgetTypeObj) {
                this.initFormParams();
                this.currentStep = 2;
            }
        },

        backToStep1() {
            this.currentStep = 1;
        },

        formIsValid() {
            let isValid = this.widgetName.length >= 3 && this.widgetName.length <= 30;
            if (this.formFields.includes("areaTypeAndId")) {
                isValid = isValid && ["site", "building", "floor", "geofence"].includes(this.areaType);
                isValid = isValid && (this.areaId ? true : false);
            }
            if (this.formFields.includes("densityTimeWindow")) {
                isValid = isValid && ["month", "week", "day", "daytime", "hour"].includes(this.densityTimeWindow);
            }
            if (this.formFields.includes("locHistoryTimeWindow")) {
                isValid = isValid && [15, 30, 45, 60].includes(this.locHistoryTimeWindow);
            }
            if (this.formFields.includes("heatmapTimeWindow")) {
                isValid = isValid && ([5, 10, 15, 30, 60, 120].includes(parseInt(this.heatmapTimeWindow)));
            }
            if (this.formFields.includes('searchDetailsTimeWindow')) {
                isValid = isValid && ['month', '15days', '5days', '24H', '12H', 'hour'].includes(this.searchDetailsTimeWindow);
            }
            if (this.formFields.includes('searchTimeWindowForROI')) {
                isValid = isValid && ['month', '15days', '5days', '24H', '12H', 'hour'].includes(this.searchTimeWindowForROI);
            }
            if (this.formFields.includes("ROIcurrency")) {
                isValid = isValid && ['$', '€', '£'].includes(this.ROIcurrency);
            }
            if (this.formFields.includes("ROIsearchMeanTime")) {
                isValid = isValid && parseFloat(this.ROIsearchMeanTime) > 0;
            }
            if (this.formFields.includes("ROIavgHourSalary")) {
                isValid = isValid && parseFloat(this.ROIavgHourSalary) > 0;
            }
            if (this.formFields.includes("assetCategoryPeople")) {
                const peopleCategoryName = this.vPeopleCategories.map((category) => category.name);
                isValid = isValid && peopleCategoryName.includes(this.assetCategoryPeople);
            }
            if (this.formFields.includes("assetCategoriesFullList")) {
                isValid = isValid && ["all", ...this.fullCategoriesNamesList].includes(this.assetCategoryHeatmap);
            }
            if (this.formFields.includes("PeopleFlow")) {
                isValid = isValid && ['1h', '2h', '4h', '8h', '12h'].includes(this.peopleFlowTW);
                // Verify now if the selection is correct
                if (this.vPeopleFlow === "all") {
                    isValid = isValid && true;
                } else if (this.vPeopleFlow === "category") {
                    isValid = isValid && this.isCorrectPeopleCategorySelected;
                } else if (this.vPeopleFlow === "asset") {
                    isValid = isValid && this.isCorrectPeopleAssetSelected;
                } else {
                    isValid = isValid && false;
                }
            }
            if (this.formFields.includes("assetThresholdInGeofence")) {
                isValid = isValid && (this.thresholdGeofence ? true : false);
            }

            return isValid;
        },

        initPeopleAssetCategorySelect2() {
            let self = this;
            // Init Select2 data of categories
            let dataCategorySelect2 = [];
            for (let i = 0; i < this.vPeopleCategories.length; i++) {
                let categoryName = this.vPeopleCategories[i].name;
                let isDefault = this.vPeopleCategories[i].isDefault;
                let image = this.vPeopleCategories[i].iconImg;
                let categoryNameTranslated;
                if (this.vPeopleCategories[i].isDefault) {
                    categoryNameTranslated = i18n.t(categoryName);
                } else {
                    categoryNameTranslated = categoryName;
                }
                dataCategorySelect2.push({
                    id: this.vPeopleCategories[i].id,
                    text: categoryNameTranslated,
                    categoryName: categoryName,
                    isDefault: isDefault,
                    image: image
                });
            }
            // Ascending sort order Select2 data by text
            dataCategorySelect2 = _.orderBy(
                dataCategorySelect2,
                [category => category.text.toLowerCase()],
                ["asc"]
            );
            // Init categories Select2
            commonVueHelper.destroySelect2(
                $("#peopleFlowWidgetOnlyCategorySelect2")
            );

            function formatCategory(categoryItem) {
                if (!categoryItem.id) return categoryItem.text;
                if (categoryItem.isDefault) {
                    return (
                        "<img class='flag' style='width:30px;margin-right:5px' src='/assets/categories/" +
                        categoryItem.categoryName +
                        "/Icon.png'/>" +
                        categoryItem.text
                    );
                } else {
                    return (
                        "<img class='iconPreviewCustomCat' style='background-image:url(" +
                        categoryItem.image +
                        ");margin-right:5px' />" +
                        categoryItem.text
                    );
                }
            }

            $("#peopleFlowWidgetOnlyCategorySelect2")
                .select2({
                    placeholder: i18n.t("analytics_selectPeopleCategory"),
                    width: "50%",
                    data: dataCategorySelect2,
                    multiple: true,
                    minimumResultsForSearch: -1,
                    escapeMarkup: function(m) {
                        return m;
                    },
                    templateResult: formatCategory,
                    templateSelection: formatCategory,
                    containerCssClass: "peopleCategorySelect2"
                })
                .val(this.vPeopleCategoriesUserSelect)
                .trigger("change")
                // emit event on change.
                .on("change", function() {
                    self.vPeopleCategoriesUserSelect = $(this).val();
                    if (self.vPeopleCategoriesUserSelect.length === 0) {
                        self.isCorrectPeopleCategorySelected = false;
                    } else {
                        self.isCorrectPeopleCategorySelected = true;
                    }
                });
        },

        initPeopleAssetSelect2() {
            let self = this;
            // Init Select2 data of asset
            let dataAssetSelect2 = [];
            for (let i = 0; i < this.vPeopleAssets.length; i++) {
                let assetName = this.vPeopleAssets[i].name;
                dataAssetSelect2.push({
                    id: this.vPeopleAssets[i].id,
                    text: assetName,
                    assetName: assetName
                });
            }
            // Ascending sort order Select2 data by text
            dataAssetSelect2 = _.orderBy(
                dataAssetSelect2,
                [asset => asset.text.toLowerCase()],
                ["asc"]
            );
            // Init assets select2
            commonVueHelper.destroySelect2($("#peopleFlowWidgetOnlyAssetSelect2"));

            $("#peopleFlowWidgetOnlyAssetSelect2")
                .select2({
                    placeholder: i18n.t("analytics_selectPeopleAsset"),
                    width: "50%",
                    data: dataAssetSelect2,
                    minimumResultsForSearch: 2,
                    multiple: true,
                    containerCssClass: "peopleCategorySelect2"
                })
                .val(this.vPeopleAssetsUserSelect)
                .trigger("change")
                // emit event on change.
                .on("change", function() {
                    // Store category index to retrieve associated subcategory
                    self.vPeopleAssetsUserSelect = $(this).val();
                    if (self.vPeopleAssetsUserSelect.length == 0) {
                        self.isCorrectPeopleAssetSelected = false;
                    } else {
                        self.isCorrectPeopleAssetSelected = true;
                    }
                });
        },

        onSubmit() {
            //make some validations
            if (!this.formIsValid()) {
                this.errorDisplayed = true;
                console.error("Component(widgetModal)::onSubmit() - No valid form");
                return;
            }
            //Gather provided information
            this.payload.siteId = this.$route.params.siteId;
            this.payload.dashboardId = this.$route.params.dashboardId;
            if (this.creationMode) {
                this.payload.widgetTypeId = this.selectedWidgetTypeId;
            } else if (this.editMode) {
                this.payload.widgetId = this.widgetToEdit.id;
                // Add widgetType in payload
                this.payload.widgetTypeId = this.widgetTypeObj.id;
            }

            this.payload.queryParams = {
                ...this.widgetTypeObj.defaultQueryParams,
                ...(this.editMode ? this.widgetToEdit.queryParams : {})
            };
            if (this.creationMode && this.widgetTypeObj.pathParamsNames.includes("SITE_ID")) {
                this.payload.pathParams = {
                    SITE_ID: this.$route.params.siteId
                };
            }

            // Add in pathParams if it is threshold widget edition
            if (this.editMode && this.widgetTypeObj.pathParamsNames.includes("SITE_ID") && this.widgetTypeObj.pathParamsNames.includes("GEOFENCE_ID")) {
                this.payload.pathParams = {
                    SITE_ID: this.$route.params.siteId
                };
            }

            this.payload.detailsInfo = {
                ...(this.editMode ? this.widgetToEdit.detailsInfo : {})
            };

            this.payload.customNameToDisplay = this.widgetName;

            switch (this.widgetTypeObj.reference) {
                case "AREA_DENSITY_OF_ASSETS":
                    this.payload.queryParams.timeWindow = this.densityTimeWindow;
                    this.payload.queryParams.areaType = this.areaType;
                    this.payload.queryParams.areaId = this.areaId;
                    break;
                case "AREA_DENSITY_OF_PEOPLE":
                    this.payload.queryParams.timeWindow = this.densityTimeWindow;
                    this.payload.queryParams.areaType = this.areaType;
                    this.payload.queryParams.areaId = this.areaId;
                    this.payload.queryParams.category = this.assetCategoryPeople;
                    break;
                case "ASSETS_LOCATION_HEATMAP":
                    this.payload.queryParams.timeWindow = this.heatmapTimeWindow;
                    this.payload.queryParams.category = this.assetCategoryHeatmap;
                    break;
                case "RETURN_ON_INVESTMENT":
                    this.payload.queryParams.timeWindow = this.searchTimeWindowForROI;
                    this.payload.detailsInfo.currency = this.ROIcurrency;
                    this.payload.detailsInfo.searchMeanTime = parseFloat(this.ROIsearchMeanTime);
                    this.payload.detailsInfo.avgHourSalary = parseFloat(this.ROIavgHourSalary);
                    break;
                case "NUMBER_OF_SEARCH":
                    this.payload.queryParams.timeWindow = this.searchDetailsTimeWindow;
                    break;
                case "PEOPLE_FLOW":
                    this.payload.queryParams.timeWindow = this.peopleFlowTW;
                    // Populate peopleFlowPeopleIds
                    if (this.vPeopleFlow === "all") {
                        // Add in map all people categories id
                        this.peopleFlowPeopleIds = "all";
                    } else if (this.vPeopleFlow === "category") {
                        // Add the list of people category which must selected by user
                        this.peopleFlowPeopleIds = this.vPeopleCategoriesUserSelect;
                    } else if (this.vPeopleFlow === "asset") {
                        // Add the list of people asset which must selected by user
                        this.peopleFlowPeopleIds = this.vPeopleAssetsUserSelect;
                    } else {
                        console.error("Component(widgetModal)::onSubmit() - Missing peopleFlow value");
                        return;
                    }
                    // Set the value of parameters
                    if (this.peopleFlowPeopleIds === "all") {
                        this.payload.queryParams.peopleId = "all";
                    } else {
                        this.payload.queryParams.peopleId = this.peopleFlowPeopleIds.join(",");
                    }
                    break;
                case "ASSETS_THESHOLD_IN_GEOFENCE":
                    // add geofence id in request parameter
                    if (this.thresholdGeofence) {
                        this.payload.pathParams.GEOFENCE_ID = this.thresholdGeofence;
                    } else {
                        console.error("Component(widgetModal)::onSubmit() - Missing geofence id");
                        return;
                    }
                    // Add the query parameter of threshold if is correct
                    if (this.thresholdNumber && this.thresholdNumber >= 1) {
                        this.payload.queryParams.assetThreshold = this.thresholdNumber;
                    } else {
                        this.errorDisplayed = true;
                        console.error("Component(widgetModal)::onSubmit() - Missing threshold number");
                        return;
                    }
                default:
                    break;
            }
            //call some action to send the payload
            console.log("Component(widgetModal)::onSubmit() - payload:", this.payload);
            if (this.creationMode) {
                this.createWidget(this.payload);
            } else if (this.editMode) {
                this.updateWidget(this.payload);
            }

        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-emptylist": EmptyList,
    }
};
</script>

<style scoped>
.cursorPointer {
    cursor: pointer;
}
.selectedWidgetType {
    border: brown solid 3px;
}
.alreadyAddedWidgetModel {
    border: green solid 3px;
    cursor: not-allowed;
}
.widget_example_img {
    border: #616161 1px solid;
    width: 160px !important;
    margin-left: 20px;
}
.widget_example_img_step2 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: black 2px solid;
    border-radius: 5px;
    width: 300px;
}
.widget-type-description {
    margin-left: 20px;
}
.toggleBottomRightButton {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.notAllowedButton {
    pointer-events: none !important;
}
.notAllowedButton:hover {
    background-color: rgba(10, 187, 135, 0.1) !important;
    color: #0abb87 !important;
}
.peopleCategorySelect2 {
  margin-left: 10px;
  margin-top: -15px;
}
</style>
<style>
.font12 {
    font-size: 12px;
}
</style>
