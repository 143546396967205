<template>
    <div>
        <div class="kt-portlet kt-portlet--height-fluid" :class="isDarkMode ? 'portletDark' : ''">
            <div class="kt-widget14">

                <div class="genericWidgetHeader">
                    <div class="kt-widget14__header">
                        <div class="kt-widget14__title genericWidgetTitle" :class="isDarkMode ? 'textWhiteForDark' : ''">
                            {{ this.widgetTitle }}
                        </div>
                        <div class="kt-widget14__desc genericWidgetTitle" :class="isDarkMode ? 'textWhiteForDark' : ''">
                            <i v-if="widgetTimeWindow" class="la la-clock-o marginR5"></i>
                            <span v-if="widgetTimeWindow == '1h'">{{ $tc("analytics_nbr_hours") }}</span>
                            <span v-else-if="widgetTimeWindow == '2h'">{{ $tc("analytics_nbr_hours", 2) }}</span>
                            <span v-else-if="widgetTimeWindow == '4h'">{{ $tc("analytics_nbr_hours", 4) }}</span>
                            <span v-else-if="widgetTimeWindow == '8h'">{{ $tc("analytics_nbr_hours", 8) }}</span>
                            <span v-else-if="widgetTimeWindow == '12h'">{{ $tc("analytics_nbr_hours", 12) }}</span>
                        </div>
                    </div>
                    <div class="genericWidgetToolbar">
                        <div data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('common_moreActions')" class="dropdown dropdown-inline">
                            <button type="button" class="btn btn-clean btn-icon btn-sm btn-icon-md moreButtonChartMain" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="flaticon-more moreButtonChart" :class="isDarkMode ? 'textWhiteForDark' : ''"></i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right">
                                <a v-if="hasDetails" class="dropdown-item" href="#" @click="displayDetails"><i class="la la-line-chart"></i> {{ $t("analytics_details") }}</a>
                                <a class="dropdown-item" href="#" @click="onEditWidget"><i class="la la-cog"></i> {{ $t("analytics_settings") }}</a>
                                <a class="dropdown-item" href="#" @click="onDeleteWidget"><i class="la la-trash"></i> {{ $t("analytics_delete") }} </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div :id="'widget-content-' + this.widgetId" class="kt-widget14__content">
                    <table class="table table-bordered table-hover" align="center" v-if="displayTable" :class="isDarkMode ? 'textWhiteForDark' : ''">
                        <thead class="thead-lightf">
                            <tr align="center">
                                <th>{{$t("analytics_PF_people")}}</th>
                                <th v-for="geo in geofencesInfo" :key="geo.id">{{ geo.name }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="people in peopleInfo" :key="people.id">
                                <td align="center">
                                    <router-link v-if="getAssetData(people.id).tag.macAddress" class="kt-link kt-user-card-v2__name" :to="{ name: 'assetsTrackingDashboard',  query: { highlight: getAssetData(people.id).tag.macAddress } }">
                                        {{ people.name }}
                                    </router-link>
                                </td>
                                <td align="center" v-for="geo in geofencesInfo" :key="geo.id">
                                    <span v-if="tableData[people.id] && tableData[people.id][geo.id]">
                                        <!-- {{$t("analytics_PF_timePassed")}}  -->
                                        <span v-if="isPeopleInGeofence[people.id] && !isPeopleInGeofence[people.id][geo.id]">{{tableData[people.id][geo.id]}}</span>
                                        <span v-else>{{tableData[people.id][geo.id]}} <span style="color: #6f42c1;font-weight: 400;">{{$t("analytics_PF_insideAreaSince")}}</span></span>
                                    </span>
                                    <span v-else>0m</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-else :class="isDarkMode ? 'textWhiteForDark' : ''">
                        <app-emptylist targetObjectName="WIDGET_PATIENT_FLOW" mainTextKey="analytics_PF_noData_main" :widgetId="widgetId" :subTextKey="'analytics_PF_noData_desc'" imgName="empty_dashboard.svg" v-bind:isDisplayed="!displayTable"></app-emptylist>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
import EmptyList from "./../utils/emptylist.vue";
export default {
    data() {
        console.log("Component(widgetPatientFlow)::data() - called");
        return {
            widgetContentData: null,
            displayError: false,
            widgetTitle: null,
            widgetDesc: null,
            hasDetails: false,
            colorPalette: ["#007bff", "#6610f2", "#6f42c1", "#e83e8c", "#dc3545", "#fd7e14", "#ffc107", "#28a745", "#20c997", "#17a2b8", "#5867dd", "#0abb87", "#5578eb", "#ffb822", "#fd397a"],
            refreshInterval: null,
            refreshSchedulerId: null,
            isOngoingFetch: false,
            perfectScrollBar: null,
            tableHeader: {},
            geofencesInfo: null,
            peopleInfo: null,
            tableData: null,
            displayTable: false,
            isPeopleInGeofence: {},
            widgetTimeWindow: null
        };
    },
    created: function() {
        console.log("Component(widgetPatientFlow)::created() - called - widgetId: " + this.widgetId);
        this.colorPalette.sort(function() {
            return Math.random() - 0.5;
        });
        this.getWidgetTitle();
    },
    destroyed: function() {
        console.log("Component(widgetPatientFlow)::destroyed() - called - widgetId: " + this.widgetId);
        if (this.refreshSchedulerId) {
            clearInterval(this.refreshSchedulerId);
        }
        if (this.perfectScrollBar) {
            this.perfectScrollBar.destroy();
            this.perfectScrollBar = null;
        }
    },
    props: {
        widgetId: {
            type: String
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        isDarkMode: function(isDarkMode) {
            this.drawPatientFlow(this.isDarkMode);
        },

        currentDashboardWidgets: {
            //immediate: true,
            deep: true,
            handler(newValue) {
                this.getWidgetTitle(newValue);
            }
        },

        widgetsDrawingContentData: {
            //immediate: true,
            deep: true,
            handler(newValue) {
                let thisWidgetContent = this.getThisWidgetDrawingData(newValue);
                if (thisWidgetContent) {
                    let thisWidget = this.getThisWidget();
                    this.widgetTimeWindow = thisWidget.queryParams.timeWindow;
                    this.widgetContentData = thisWidgetContent.drawingData || null;
                }
            }
        },

        widgetContentData: {
            deep: true,
            handler: function(newValue) {
                this.hasDetails = newValue && newValue.details ? true : false;
                this.drawPatientFlow(this.isDarkMode);
            }
        },

        currentDashboard: {
            immediate: true,
            deep: true,
            handler(newValue) {
                this.refreshInterval = newValue ? (parseInt(newValue.refreshInterval) || null) : null;
            }
        },

        refreshInterval: {
            immediate: true,
            handler(newValue) {
                console.log("Component(widgetPatientFlow)::watch(refreshInterval) handle with :", newValue);
                this.scheduleRefresh();
            }
        },

        widgetsLoadingState: {
            immediate: true,
            handler(newValue) {
                if (newValue.hasOwnProperty(this.widgetId)) {
                    this.isOngoingFetch = newValue[this.widgetId] ? true : false;
                } else if (this.refreshSchedulerId) {
                    // cancel scheduler
                }
            }
        },

        "$i18n.locale": function (v) {
            //this.drawChart(this.isDarkMode);
        }

    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["currentDashboard", "currentDashboardWidgets", "widgetsDrawingContentData", "widgetsLoadingState", "isDarkMode", "siteGeofences"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["showDeleteModal", "getWidgetContent", "setWidgetToEdit", "setWidgetToDetail"]),

        getAssetData: function(assetId) {
            const asset = this.$store.getters.getSiteAssetById(assetId);
            return asset;
        },

        getThisWidget: function(listWidgets) {
            let myWidget = (listWidgets || this.currentDashboardWidgets).filter(function(widgetObject) {
                return widgetObject.id === this.widgetId;
            }, this)[0];
            //console.log("Component(widgetPatientFlow)::getThisWidget() - myWidget = " + JSON.stringify(myWidget, null, 2));
            return myWidget;
        },

        getThisWidgetDrawingData: function(data) {
            return (data || this.widgetsDrawingContentData).filter(function(widgetContent) {
                return widgetContent.widgetId === this.widgetId;
            }, this)[0];
        },

        drawPatientFlow: function(isDarkMode) {

            if (!(Array.isArray(this.widgetContentData) && this.widgetContentData.length > 0)) {
                return;
            }

            this.tableHeader = {};
            this.peopleInfo = [];
            this.isPeopleInGeofence = {};
            let self = this;

            /* Received widgetContentData format is as follows :
                [
                    {
                        "people": {
                            "id": "6261a50fbf7949cdf164d86e",
                            "name": "Patient_3"
                        },
                        "flow": [
                            {
                                "geofenceId": "63184ee102d714a1fa3dd140",
                                "duration": 6000,
                                "periods": [
                                    {
                                        "startDate": 1662535869175,
                                        "endDate": 1662535990528
                                    },
                                    {
                                        "startDate": 1662537906438,
                                        "endDate": 1662541235239
                                    }
                                ],
                                "isStillInGeofence": true
                            },
                            {
                                "geofenceId": "63184ee102d714a1fa3dd141",
                                "duration": 10000,
                                "periods": [
                                    {
                                        "startDate": 1662533369175,
                                        "endDate": 1662534490528
                                    }
                                ],
                                "isStillInGeofence": false
                            }
                        ]
                    },
                    {
                        "people": {
                            "id": "6261a50fbf7949cdf164d86d",
                            "name": "Patient_2"
                        },
                        "flow": [
                            {...}
                        ]
                    },
                    ...
                ]
            */

            // create new array with 
            // index = peopleId
            // value = array of geofenceId => durations ("xxd yyh zzmin") 
            this.tableData = this.widgetContentData.reduce(function (cumul, peopleData) {
                cumul[peopleData.people.id] = self.computeDurationPerGeofence(peopleData.flow);
                self.peopleInfo.push({...peopleData.people});
                return cumul;
            }, {});

            // create new array with 
            // index = peopleId
            // value = array of geofenceId => presenceInGeofence (true/false) 
            this.isPeopleInGeofence = this.widgetContentData.reduce(function (cumul, peopleData) {
                cumul[peopleData.people.id] = peopleData.flow.reduce(function (cumul2, geofenceData) {
                    cumul2[geofenceData.geofenceId] = geofenceData.isStillInGeofence;
                    return cumul2;
                }, {});
                return cumul;
            }, {});

            this.geofencesInfo = Object.keys(this.tableHeader).sort().map(function(geofenceId) {
                let geo = this.siteGeofences.filter(function (geo) {
                    return geo.id === geofenceId;
                })[0] || null;
                return {id: geofenceId, name: geo ? geo.name : null};
            }, this);

            this.displayTable = this.geofencesInfo.length > 0 && this.peopleInfo.length > 0;

            this.peopleInfo.sort(function(p1, p2) {
                let n1 = Object.keys(self.tableData[p1.id]).length;
                let n2 = Object.keys(self.tableData[p2.id]).length;

                return n2 - n1;
            });

            if (this.perfectScrollBar) {
                this.perfectScrollBar.update();
            } else {
                this.perfectScrollBar = new PerfectScrollbar("#widget-content-" + this.widgetId, {
                    wheelSpeed: 0.1,
                    wheelPropagation: true,
                    //suppressScrollX: true
                });
            }

        },

        computeDurationPerGeofence(flowList) {
            // Compile rawDuration map
            // For each geofenceId, attach duration in minutes
            let rawDurations = flowList.reduce(function (cumul, geofenceData) {
                cumul[geofenceData.geofenceId] = Math.ceil( Math.abs(geofenceData.duration)/60000 );
                return cumul;
            }, {});

            // Convert duration in minutes to a string "xxd yyh zzmin" for display in UI
            for (let geofence in rawDurations) {
                this.tableHeader[geofence] = true;
                let durationInMinutes = rawDurations[geofence];

                let nbrMinutes = durationInMinutes % 60;
                let nbrHours = Math.floor(durationInMinutes/60)%24;
                let nbrDays = Math.floor(durationInMinutes/(24*60));
                let durationToDisplay = "";
                if (nbrDays > 0) {
                    durationToDisplay = "+" + i18n.tc("analytics_nbr_days_short", nbrDays);
                } else if (nbrHours > 0) {
                    durationToDisplay = nbrHours + "h "; //i18n.tc("analytics_nbr_hours_short", nbrHours);
                }
                if (!nbrDays && (nbrMinutes > 0 || !nbrHours)) {
                    durationToDisplay += nbrMinutes + " min";
                }
                rawDurations[geofence] = durationToDisplay;
            }
            return rawDurations;
        },

        onDeleteWidget: function () {
            this.showDeleteModal({ textContent: i18n.t("analytics_deleteWidgetConfirmText", { widgetName: this.widgetTitle }), actionName: "DELETE_WIDGET", params: { siteId: this.$route.params.siteId, dashboardId: this.$route.params.dashboardId, widgetId: this.widgetId } });
        },

        onEditWidget: function() {
            this.setWidgetToEdit(this.widgetId);
        },

        displayDetails() {
            this.setWidgetToDetail(this.widgetId);
        },

        scheduleRefresh: function() {
            if (this.refreshSchedulerId) {
                clearInterval(this.refreshSchedulerId);
            }
            this.smartFetchData();
            if (parseInt(this.refreshInterval)) {
                let self = this;
                this.refreshSchedulerId = setInterval(function() {
                    console.log("Component(widgetPatientFlow)::scheduleRefresh() -> widget " + self.widgetId + " setInterval called at " + self.refreshInterval + " seconds");
                    self.smartFetchData();
                }, parseInt(this.refreshInterval)*1000);
            }
        },

        smartFetchData() {
            if(!this.isOngoingFetch) {
                this.getWidgetContent(this.widgetId);
            }
        },

        formatWidgetTitle(thisWidget) {
            let isDefaultName = thisWidget.widgetType.defaultNameKey === thisWidget.customNameToDisplay;
            this.widgetTitle = isDefaultName ? i18n.t(thisWidget.customNameToDisplay) : thisWidget.customNameToDisplay;
        },

        getWidgetTitle(newValue) {
            const thisWidget = this.getThisWidget(newValue);
            if (thisWidget) {
                this.formatWidgetTitle(thisWidget);
                this.widgetDesc = thisWidget.widgetType.defaultNameKey + "_desc";
            }
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-emptylist": EmptyList
    }
};
</script>
