<template>

    <div class="generic-widget-tile" v-if="widgetInfo">
        <!-- div class="generic-widget-tile" v-if="widgetInfo">-->

        <app-widgetcounter v-if="this.widgetInfo && this.widgetInfo.widgetType && this.widgetInfo.widgetType.displayType === 'Number'" v-bind:widgetId="widgetId" class="w-100"></app-widgetcounter>

        <app-widgetthreshold v-else-if="this.widgetInfo && this.widgetInfo.widgetType && this.widgetInfo.widgetType.displayType === 'Threshold'" v-bind:widgetId="widgetId" class="w-100"></app-widgetthreshold>

        <app-widgetroi v-else-if="this.widgetInfo && this.widgetInfo.widgetType && this.widgetInfo.widgetType.displayType === 'ROI'" v-bind:widgetId="widgetId" class="w-100"></app-widgetroi>

        <app-widgetpiechart v-else-if="this.widgetInfo && this.widgetInfo.widgetType && this.widgetInfo.widgetType.displayType === 'Pie_Chart'" v-bind:widgetId="widgetId" class="w-100"></app-widgetpiechart>

        <app-widgetbarchart v-else-if="this.widgetInfo && this.widgetInfo.widgetType && this.widgetInfo.widgetType.displayType === 'Bar_Chart'" v-bind:widgetId="widgetId" class="w-100"></app-widgetbarchart>

        <app-widgetheatmap v-else-if="this.widgetInfo && this.widgetInfo.widgetType && this.widgetInfo.widgetType.displayType === 'Heatmap'" v-bind:widgetId="widgetId" class="w-100"></app-widgetheatmap>

        <app-widgetpeopleflow v-else-if="this.widgetInfo && this.widgetInfo.widgetType && this.widgetInfo.widgetType.displayType === 'People_Flow'" v-bind:widgetId="widgetId" class="w-100"></app-widgetpeopleflow>

        <div v-else class="w-100" style="display:none;"> Error ! {{this.widgetInfo && this.widgetInfo.widgetType ? this.widgetInfo.widgetType.displayType : "Undefined type of widget " + widgetId}}</div>

    </div>
    <div v-else>
    </div>

</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
import widgetCounter from "./widgetCounter.vue";
import widgetThreshold from "./widgetThreshold.vue";
import widgetPieChart from "./widgetPieChart.vue";
import widgetBarChart from "./widgetBarChart.vue";
import widgetHeatMap from "./widgetHeatMap.vue";
import widgetROI from "./widgetROI.vue";
import widgetPeopleFlow from "./widgetPeopleFlow.vue";
export default {
    data() {
        //console.log("Component(genericWidgetTile)::data() - called");
        return {
            widgetInfo: null
        };
    },
    created: function() {
        //console.log("Component(genericWidgetTile)::created() - called with widgetId: " + this.widgetId);
        var thisWidget = this.getThisWidget();
        if (thisWidget) {
            this.widgetInfo = thisWidget;
        }
    },
    props: {
        widgetId: {
            type: String
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        currentDashboardWidgets: {
            immediate: true,
            deep: true,
            handler() {
                var thisWidget = this.getThisWidget();
                if (thisWidget) {
                    this.widgetInfo = thisWidget;
                } else {
                    this.widgetInfo = null;
                }
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["currentDashboard", "currentDashboardWidgets"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---

        getThisWidget: function() {
            return this.currentDashboardWidgets.filter(function(widgetObject) {
                return widgetObject.id === this.widgetId;
            }, this)[0];
        }

    },
    components: {
        "app-widgetcounter": widgetCounter,
        "app-widgetthreshold": widgetThreshold,
        "app-widgetpiechart": widgetPieChart,
        "app-widgetbarchart": widgetBarChart,
        "app-widgetheatmap": widgetHeatMap,
        "app-widgetroi": widgetROI,
        "app-widgetpeopleflow": widgetPeopleFlow
    }
};
</script>
