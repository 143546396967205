<template>
    <div class="generic-widget-barchart">
        <div class="kt-portlet kt-portlet--height-fluid barChart" :class="isDarkMode ? 'portletDark' : ''">
            <div class="kt-widget14">
                <div class="genericWidgetHeader">
                    <div class="kt-widget14__header">
                        <div class="kt-widget14__title genericWidgetTitle" :class="isDarkMode ? 'textWhiteForDark' : ''">
                            {{ this.widgetTitle }}
                        </div>
                        <div class="kt-widget14__desc genericWidgetTitle" :class="isDarkMode ? 'textWhiteForDark' : ''">
                            <i v-if="widgetTimeWindow" class="la la-clock-o marginR5"></i>
                            <span v-if="widgetTimeWindow == 'week'">{{ $t("analytics_selectDensityTW_week") }}</span>
                            <span v-if="widgetTimeWindow == 'day'">{{ $t("analytics_selectDensityTW_day") }}</span>
                            <span v-if="widgetTimeWindow == 'daytime'">{{ $t("analytics_selectDensityTW_daytime") }}</span>
                            <span v-if="widgetTimeWindow == 'hour'">{{ $t("analytics_selectDensityTW_hour") }}</span>
                            <span style="margin-left: 5px;margin-right: 5px;" v-if="this.widgetArea">|</span>
                            <i v-if="this.widgetArea" class="la la-map-marker" style="margin-right: 3px;"></i>
                            <span v-if="this.widgetArea">{{ this.widgetArea }}</span>
                        </div>
                    </div>
                    <div class="genericWidgetToolbar">
                        <a href="#" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('common_drag')" class="btn btn-clean btn-icon btn-sm btn-icon-md moreButtonChartMain draggable-handle" :class="isDarkMode ? 'backgroundMoreBtnDarkHover' : ''">
                            <i class="flaticon2-cube-1" :class="isDarkMode ? 'textWhiteForDark' : ''"></i>
                        </a>
                        <div data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('common_moreActions')" class="dropdown dropdown-inline">
                            <button type="button" class="btn btn-clean btn-icon btn-sm btn-icon-md moreButtonChartMain" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="flaticon-more moreButtonChart" :class="isDarkMode ? 'textWhiteForDark' : ''"></i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" href="#" @click="onEditWidget"><i class="la la-cog"></i> {{ $t("analytics_settings") }}</a>
                                <a class="dropdown-item" href="#" @click="onDeleteWidget"><i class="la la-trash"></i> {{ $t("analytics_delete") }} </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="kt-widget14__content genericWidgetContentChart">
                    <div class="kt-widget14__chart" style="width: 100%">
                        <div style="position: relative; margin: auto; height: 180px; width: 100%">
                            <canvas :id="'widget-chart-' + this.widgetId"></canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
let ChartJS = require('chart.js');
export default {
    data() {
        //console.log("Component(widgetBarChart)::data() - called");
        return {
            widgetContentData: null,
            displayError: false,
            widgetTitle: null,
            widgetDesc: null,
            colorPalette: ["#007bff", "#6610f2", "#6f42c1", "#e83e8c", "#dc3545", "#fd7e14", "#ffc107", "#28a745", "#20c997", "#17a2b8", "#6c757d", "#5867dd", "#0abb87", "#5578eb", "#ffb822", "#fd397a"],
            refreshInterval: null,
            refreshSchedulerId: null,
            isOngoingFetch: false,
            formattedData: null,
            widgetColor: "#000",
            chart: null,
            language: null,
            widgetTimeWindow: null,
            widgetArea: null
        };
    },
    created: function() {
        console.log("Component(widgetBarChart)::created() - called - widgetId: " + this.widgetId);
        let colorPalette = ["#007bff", "#6610f2", "#6f42c1", "#e83e8c", "#dc3545", "#fd7e14", "#ffc107", "#28a745", "#20c997", "#17a2b8", "#6c757d", "#5867dd", "#0abb87", "#5578eb", "#ffb822", "#fd397a"];
        this.widgetColor = colorPalette[Math.floor(Math.random()*colorPalette.length)]
        this.getWidgetTitle();
    },
    destroyed: function() {
        console.log("Component(widgetBarChart)::destroyed() - called - widgetId: " + this.widgetId);
        if (this.refreshSchedulerId) {
            clearInterval(this.refreshSchedulerId);
        }
    },
    props: {
        widgetId: {
            type: String
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --

        isDarkMode: function(isDarkMode) {
            if (this.chart) {
                if (isDarkMode){
                    this.chart.options.scales.xAxes[0].ticks.minor.fontColor = "white";
                    this.chart.options.scales.xAxes[0].ticks.major.fontColor = "white";
                    this.chart.options.scales.xAxes[0].gridLines.color = "#1d1f49";
                    this.chart.options.scales.yAxes[0].ticks.minor.fontColor = "white";
                    this.chart.options.scales.yAxes[0].ticks.major.fontColor = "white";
                    this.chart.options.scales.yAxes[0].gridLines.color = "#1d1f49";
                    this.chart.update();
                } else {
                    this.chart.options.scales.xAxes[0].ticks.minor.fontColor = "gray";
                    this.chart.options.scales.xAxes[0].ticks.major.fontColor = "gray";
                    this.chart.options.scales.xAxes[0].gridLines.color = "#E5E5E5";
                    this.chart.options.scales.yAxes[0].ticks.minor.fontColor = "gray";
                    this.chart.options.scales.yAxes[0].ticks.major.fontColor = "gray";
                    this.chart.options.scales.yAxes[0].gridLines.color = "#E5E5E5";
                    this.chart.update();
                }
            }
        },

        currentDashboardWidgets: {
            deep: true,
            handler(newValue) {
                this.getWidgetTitle(newValue);
            }
        },

        widgetsDrawingContentData: {
            deep: true,
            handler(newValue) {
                let thisWidgetContent = this.getThisWidgetDrawingData(newValue);
                if (thisWidgetContent) {
                    this.widgetContentData = thisWidgetContent.drawingData || null;
                }
            }
        },

        widgetContentData: {
            deep: true,
            handler: function (newValue) {
                if (newValue && newValue.data && newValue.data.length) {
                    this.formattedData = newValue.data.map(function (elem) {
                        return {
                            x: this.$moment(elem["interval"][0]),
                            y: elem["value"]
                        }
                    }, this);
                    this.drawChart();
                } else {
                    this.formattedData = null;
                }
            }
        },

        currentDashboard: {
            immediate: true, // To force trigger watcher when the component is being created. Because it is set in viewDashoboard before the widgetThreshold creation
            deep: true, // Watcher whenever any of the watched object properties change regardless of their nested depth
            handler(newValue) {
                this.refreshInterval = newValue ? (parseInt(newValue.refreshInterval) || null) : null;
            }
        },

        refreshInterval: {
            immediate: true, // To force trigger watcher when the component is being created. Because it is set in viewDashboard before the widgetThreshold creation
            handler(newValue) {
                console.log("Component(widgetBarChart)::watch(refreshInterval) handle with :", newValue);
                this.scheduleRefresh();
            }
        },

        widgetsLoadingState: {
            immediate: true,
            handler(newValue) {
                if (newValue.hasOwnProperty(this.widgetId)) {
                    this.isOngoingFetch = newValue[this.widgetId] ? true : false;
                } else if (this.refreshSchedulerId) {
                    // cancel scheduler
                }
            }
        },

        "$i18n.locale": {
            immediate: true,
            handler: function (v) {
                this.language = v || null;
            }
        }

    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["currentDashboard", "currentDashboardWidgets", "widgetsDrawingContentData", "widgetsLoadingState", "isDarkMode"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["showDeleteModal", "getWidgetContent", "setWidgetToEdit"]),

        getThisWidget: function(listWidgets) {
            let myWidget = (listWidgets || this.currentDashboardWidgets).filter(function(widgetObject) {
                return widgetObject.id === this.widgetId;
            }, this)[0];
            return myWidget;
        },

        getThisWidgetDrawingData: function(data) {
            return (data || this.widgetsDrawingContentData).filter(function(widgetContent) {
                return widgetContent.widgetId === this.widgetId;
            }, this)[0];
        },

        drawChart: function () {
            let speakFrench = this.language === "fr";
            if (!(this.formattedData && this.widgetContentData && this.widgetContentData.inputs && this.widgetContentData.inputs.timeWindow)) {
                this.displayError = true;
                return;
            }
            let unit;
            let unitStepSize;
            let displayFormats = {};
            let siteName;
            this.widgetTimeWindow = this.widgetContentData.inputs.timeWindow;

            switch (this.widgetContentData.inputs.areaType) {
                case 'site':
                    siteName = this.currentDashboard.site.name;
                    this.widgetArea = siteName;
                    break;
                case 'building':
                    siteName = this.currentDashboard.site.name;
                    let building = this.$store.getters.getBuildingInSiteBuildingsById(this.widgetContentData.inputs.areaId);
                    if (building) {
                        this.widgetArea = building.name;
                    }
                    break;
                case 'floor':
                    let floor = this.$store.getters.getFloorInSiteBuildingsById(this.widgetContentData.inputs.areaId);
                    if (floor) {
                        this.widgetArea = floor.name;
                    }
                    break;
                case 'geofence':
                    let geofence = this.$store.getters.getGeofenceById(this.widgetContentData.inputs.areaId);
                    if (geofence) {
                        this.widgetArea = geofence.name;
                    }
                    break;
                default:
                    break;
            }

            switch (this.widgetContentData.inputs.timeWindow) {
                case 'month':
                    unit = 'day';
                    unitStepSize = 2;
                    displayFormats = {day: speakFrench ? "D MMM" : "MMM D"};
                    break;
                case 'week':
                    unit = 'day';
                    unitStepSize = 1;
                    displayFormats = {day: speakFrench ? "D MMM" : "MMM D"};
                    break;
                case 'day':
                    unit = 'hour';
                    unitStepSize = 2;
                    displayFormats = {hour: speakFrench ? "H:mm" : "hA"};
                    break;
                case 'daytime':
                    unit = 'hour';
                    unitStepSize = 1;
                    displayFormats = {hour: speakFrench ? "H:mm" : "hA"};
                    break;
                case 'hour':
                    unit = 'minute';
                    unitStepSize = 5;
                    displayFormats = {minute:  speakFrench ? "H:mm" : "h:mm a"};
                    break;
                default:
                    break;
            }
            let scales = {
                xAxes: [{
                    type: 'time',
                    gridLines: {display: false},
                    time: {unit, unitStepSize, displayFormats},
                    offset: true,
                    ticks: {
                        //fontSize: 10,
                        autoSkip: false,
                        maxRotation: 90,
                        minRotation: 45,
                        minor: {},
                        major: {}
                    }
                }],
                yAxes: [{
                    gridLines: {},
                    ticks:{
                        beginAtZero: true,
                        stepSize: 1,
                        minor: {},
                        major: {}
                    }
                }
                ]
            }

            if (this.isDarkMode){
                scales.xAxes[0].ticks.minor.fontColor = "white";
                scales.xAxes[0].ticks.major.fontColor = "white";
                scales.xAxes[0].gridLines.color = "#1d1f49";
                scales.yAxes[0].ticks.minor.fontColor = "white";
                scales.yAxes[0].ticks.major.fontColor = "white";
                scales.yAxes[0].gridLines.color = "#1d1f49";
            } else {
                scales.xAxes[0].ticks.minor.fontColor = "gray";
                scales.xAxes[0].ticks.major.fontColor = "gray";
                scales.xAxes[0].gridLines.color = "#E5E5E5";
                scales.yAxes[0].ticks.minor.fontColor = "gray";
                scales.yAxes[0].ticks.major.fontColor = "gray";
                scales.yAxes[0].gridLines.color = "#E5E5E5";
            }

            let self = this;
            if (this.chart) {
                this.chart.data.datasets[0].data = this.formattedData;
                this.chart.options.scales = scales;
                this.chart.update();
            } else {
                let ctx = $("#widget-chart-" + this.widgetId).empty();
                this.chart = new ChartJS(ctx, {
                    type: 'bar',
                    data: {
                        datasets: [
                            {
                                backgroundColor: this.widgetColor,
                                data: this.formattedData
                            }
                        ]
                    },
                    options: {
                        legend: {display: false},
                        scales: scales,
                        responsive: true,
                        maintainAspectRatio: false,
                        tooltips: {
                            callbacks: {
                                title: function(tooltipItem, data) {
                                    if (tooltipItem && tooltipItem.length > 1 && Number.isInteger(tooltipItem[0]['index'])) {
                                        let formattedData = self.formattedData[tooltipItem[0]['index']];
                                        if (formattedData && formattedData['x']) {
                                            return (formattedData['x']).calendar();
                                        }
                                    }
                                },
                                label: function(tooltipItem) {
                                    if (tooltipItem) {
                                        return i18n.tc("analytics_nbr_assets", Number(tooltipItem.value));
                                    }
                                }
                            }
                        },
                    }
                });
                window.myc = this.chart;
            }

            if (this.isDarkMode){
                $("#widget-chart-" + this.widgetId).closest(".kt-portlet").addClass("portletDark");
                $("#widget-chart-" + this.widgetId).closest(".kt-portlet").find(".genericWidgetTitle").addClass("textWhiteForDark");
                $("#widget-chart-" + this.widgetId).closest(".kt-portlet").find(".moreButtonChartMain").addClass("backgroundMoreBtnDarkHover");
                $("#widget-chart-" + this.widgetId).closest(".kt-portlet").find(".moreButtonChart").addClass("textWhiteForDark");
            } else {
                $("#widget-chart-" + this.widgetId).closest(".kt-portlet").removeClass("portletDark");
                $("#widget-chart-" + this.widgetId).closest(".kt-portlet").find(".genericWidgetTitle").removeClass("textWhiteForDark");
                $("#widget-chart-" + this.widgetId).closest(".kt-portlet").find(".moreButtonChartMain").removeClass("backgroundMoreBtnDarkHover");
                $("#widget-chart-" + this.widgetId).closest(".kt-portlet").find(".moreButtonChart").removeClass("textWhiteForDark");
            }

        },

        onDeleteWidget: function () {
            this.showDeleteModal({ textContent: i18n.t("analytics_deleteWidgetConfirmText", { widgetName: this.widgetTitle }), actionName: "DELETE_WIDGET", params: { siteId: this.$route.params.siteId, dashboardId: this.$route.params.dashboardId, widgetId: this.widgetId } });
        },

        onEditWidget: function() {
            this.setWidgetToEdit(this.widgetId);
        },

        scheduleRefresh: function () {
            if (this.refreshSchedulerId) {
                clearInterval(this.refreshSchedulerId);
            }
            this.smartFetchData();
            if (parseInt(this.refreshInterval)) {
                let self = this;
                this.refreshSchedulerId = setInterval(function() {
                    console.log("Component(widgetBarChart)::scheduleRefresh() -> widget " + self.widgetId + " setInterval called at " + self.refreshInterval + " seconds");
                    self.smartFetchData();
                }, parseInt(this.refreshInterval)*1000);
            }
        },

        smartFetchData() {
            if(!this.isOngoingFetch) {
                this.getWidgetContent(this.widgetId);
            }
        },

        formatWidgetTitle(thisWidget) {
            let isDefaultName = thisWidget.widgetType.defaultNameKey === thisWidget.customNameToDisplay;
            this.widgetTitle = isDefaultName ? i18n.t(thisWidget.customNameToDisplay) : thisWidget.customNameToDisplay;
        },

        getWidgetTitle(newValue) {
            const thisWidget = this.getThisWidget(newValue);
            if (thisWidget) {
                this.formatWidgetTitle(thisWidget);
                this.widgetDesc = thisWidget.widgetType.defaultNameKey + "_desc";
            }
        }
    }
};
</script>
