<template>
    <div class="modal fade show" id="widgetInfoModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-modal="true" style="display: none">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title"> {{ widgetTitle }} </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="forceCloseModal"></button>
                </div>
                <div class="modal-body overflowContent">
                    <!--begin emptyList component-->
                    <app-emptylist targetObjectName="WIDGETSDETAILMODAL_LIST" mainTextKey="analytics_emptyCommonDetails" :subTextKey="emptyDetailSubLabel" imgName="empty_dashboard.svg" v-bind:isDisplayed="!hasDetailsToShow"></app-emptylist>
                    <!--end emptyList component-->

                    <!-- Start of availability table portlet -->
                    <div class="kt-portlet kt-portlet--tabs" style=" box-shadow: none; margin: -15px; " v-if="hasDetailsToShow && displayAssetAvailibiltyDetail">
                        <div class="kt-portlet__head">
                            <div class="kt-portlet__head-toolbar">
                                <ul class="nav nav-tabs nav-tabs-bold nav-tabs-line nav-tabs-line-right nav-tabs-line-brand" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" data-toggle="tab" href="#kt_portlet_tab_1_1" role="tab" aria-selected="true">
                                            {{ $t("analytics_assetAvailabilityDetailWidgetTableAvailable") }}
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-toggle="tab" href="#kt_portlet_tab_1_2" role="tab" aria-selected="false">
                                            {{ $t("analytics_assetAvailabilityDetailWidgetTableUnavailable") }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="kt-portlet__body">
                            <div class="tab-content">
                                <div class="tab-pane active" id="kt_portlet_tab_1_1">
                                    <table class="table table-bordered table-hover" align="center" style="margin-top:10px;" v-if="detailAssetAvailableTableHeader.length > 0 && detailAssetAvailableTableBody.length > 0">
                                        <thead class="thead-light">
                                            <tr align="center">
                                                <th v-for="header in detailAssetAvailableTableHeader" :key="header">{{ header }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(tableLine, index) in detailAssetAvailableTableBody" :key="index">
                                                <td align="center"> {{ tableLine.category }} </td>
                                                <td align="center"> {{ tableLine.assetCount }} </td>
                                                <td v-if="hyperlinkAssetName">
                                                    <a v-for="(asset, index) in tableLine.assets" :key="index" class="kt-link kt-user-card-v2__name" style="margin-right: 5px;" @click="onClickAssetName(asset.id)" href="#">
                                                        <span v-if="index !== tableLine.assets.length - 1"> {{ asset.name }}, </span>
                                                        <span v-else> {{ asset.name }} </span>
                                                    </a>
                                                </td>
                                                <td v-else>
                                                    <div v-for="(asset, index) in tableLine.assets" :key="index" class="kt-user-card-v2__name" style="margin-right: 5px;">
                                                        <span v-if="index !== tableLine.assets.length - 1"> {{ asset.name }}, </span>
                                                        <span v-else> {{ asset.name }} </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <!--begin emptyList component-->
                                    <app-emptylist targetObjectName="WIDGETSDETAILMODAL_LIST" mainTextKey="analytics_emptyAssetAvailableDetailsLabel" subTextKey="analytics_emptyAssetAvailableDetailsSubLabel" imgName="empty_asset.svg" v-bind:isDisplayed="detailAssetAvailableTableHeader.length === 0 && detailAssetAvailableTableBody.length === 0"></app-emptylist>
                                    <!--end emptyList component-->
                                </div>
                                <div class="tab-pane" id="kt_portlet_tab_1_2">
                                    <table class="table table-bordered table-hover" align="center" style="margin-top:10px;" v-if="detailAssetUnavailableTableHeader.length > 0 && detailAssetUnavailableTableBody.length > 0">
                                        <thead class="thead-light">
                                            <tr align="center">
                                                <th v-for="header in detailAssetUnavailableTableHeader" :key="header">{{ header }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(tableLine, index) in detailAssetUnavailableTableBody" :key="index">
                                                <td align="center"> {{ tableLine.category }} </td>
                                                <td align="center"> {{ tableLine.assetCount }} </td>
                                                <td v-if="hyperlinkAssetName">
                                                    <a  data-toggle="kt-tooltip" v-for="(asset, index) in tableLine.assets" :key="index" class="kt-link kt-user-card-v2__name" style="margin-right: 5px;" @click="onClickAssetName(asset.id)" href="#">
                                                        <span v-if="index !== tableLine.assets.length - 1"> {{ asset.name }}, </span>
                                                        <span v-else> {{ asset.name }} </span>
                                                    </a>
                                                </td>
                                                <td v-else>
                                                    <div data-toggle="kt-tooltip" v-for="(asset, index) in tableLine.assets" :key="index" class="kt-user-card-v2__name" style="margin-right: 5px;">
                                                        <span v-if="index !== tableLine.assets.length - 1"> {{ asset.name }}, </span>
                                                        <span v-else> {{ asset.name }} </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <app-emptylist targetObjectName="WIDGETSDETAILMODAL_LIST" mainTextKey="analytics_emptyAssetUnavailableDetailsLabel" subTextKey="analytics_emptyAssetUnavailableDetailsSubLabel" imgName="empty_asset.svg" v-bind:isDisplayed="detailAssetUnavailableTableHeader.length === 0 && detailAssetUnavailableTableBody.length === 0"></app-emptylist>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Start of availability table portlet -->
                    <table class="table table-bordered table-hover" align="center" style="margin-top: 10px;" v-if="hasDetailsToShow && displaySimpleTableDetail">
                        <thead class="thead-light">
                            <tr align="center">
                                <th v-for="header in detailTableHeader" :key="header">{{ header }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(tableLine, index) in detailTableBody" :key="index">
                                <td v-for="(lineColumn, index) in tableLine" :key="index" align="center">{{ lineColumn }}</td>
                                <td v-if="displayAssetName && hyperlinkAssetName">
                                    <a  data-toggle="kt-tooltip" v-for="(asset, assetTableIndex) in detailAssetNameTableBody[index]" :key="assetTableIndex" class="kt-link kt-user-card-v2__name" style="margin-right: 5px;" @click="onClickAssetName(asset.id)" href="#">
                                        <span v-if="assetTableIndex !== detailAssetNameTableBody[index].length - 1"> {{ asset.name }}, </span>
                                        <span v-else> {{ asset.name }} </span>
                                    </a>
                                </td>
                                <td v-else-if="displayAssetName">
                                    <div data-toggle="kt-tooltip" v-for="(asset, assetTableIndex) in detailAssetNameTableBody[index]" :key="assetTableIndex" class="kt-user-card-v2__name" style="margin-right: 5px;">
                                        <span v-if="assetTableIndex !== detailAssetNameTableBody[index].length - 1"> {{ asset.name }}, </span>
                                        <span v-else> {{ asset.name }} </span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="forceCloseModal">
                        {{ $t("common_close") }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import EmptyList from "./../utils/emptylist.vue";
import i18n from "../../i18n";

export default {
    data() {
        return {
            //modal inputs
            siteId: this.$route.params.siteId,
            widgetId: null,
            widgetObj: null,
            widgetContentData: null,
            widgetTypeReference: null,
            detailTableHeader: [],
            detailTableBody: [],
            detailAssetNameTableBody: [],
            detailAssetAvailableTableHeader: [],
            detailAssetUnavailableTableHeader: [],
            detailAssetAvailableTableBody: [],
            detailAssetUnavailableTableBody: [],
            emptyDetailSubLabel: null,
            widgetTitle: null,

            //widget we want to show details
            widgetWithDetails: [
                "NUMBER_OF_SEARCH",
                "NUMBER_OF_ASSETS_PER_UNIT",
                "NUMBER_OF_ASSETS_PER_BATTERY_LEVEL",
                "NUMBER_OF_ASSETS_PER_AVAILABILITY",
                "NUMBER_OF_ASSETS_PER_TAG_TYPE",
                "NUMBER_OF_ASSETS_PER_CATEGORY",
                "ASSETS_THESHOLD_IN_GEOFENCE"
            ],
            //modal params
            hasDetailsToShow: false,
            displaySimpleTableDetail: false,
            displayAssetAvailibiltyDetail: false,
            displayAssetName: false,
            hyperlinkAssetName: false
        };
    },
    created: function() {
        console.log("Component(widgetDetailsModal)::created() - called");
        this.hyperlinkAssetName = this.isHyperlinkAssetName();
        this.getSiteAssets(this.siteId);
        this.getSiteById(this.siteId);
    },
    mounted: function() {
        console.log("Component(widgetDetailsModal)::mounted() - called");
    },
    destroyed: function() {
        console.log("Component(widgetDetailsModal)::destroyed() - called");
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        widgetToDisplayDetails: {
            deep: true,
            handler(newValue) {
                //console.log("watch (widgetInfoModal) :: widgetToDisplayDetails", newValue);
                this.widgetId = newValue || null;
                this.widgetObj = this.getThisWidget() || null;
                this.widgetContentData = this.getThisWidgetDrawingData() || null;
                this.widgetTypeReference = (this.widgetObj && this.widgetObj.widgetType && this.widgetObj.widgetType.reference) || null;
                let hasDetails = this.widgetWithDetails.includes(this.widgetTypeReference);
                if (!hasDetails || !this.widgetContentData) {
                    this.forceCloseModal();
                    return;
                }
                this.resetModalParams();
                this.hasDetailsToShow = hasDetails;
                this.initModalParams();
                if (this.widgetTypeReference === "ASSETS_THESHOLD_IN_GEOFENCE") {
                    // Give the widgetContentData
                    this.initTableDetailContent(this.widgetTypeReference, this.widgetContentData);
                } else {
                    this.initTableDetailContent(this.widgetTypeReference, this.widgetContentData.details);
                }
                $("#widgetInfoModal").modal("show");

            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["widgetToDisplayDetails", "currentDashboardWidgets", "widgetsDrawingContentData", "siteAssets", "currentSite", "getGeofenceById", "hasAccess"])

    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---,
        ...mapActions(["unsetWidgetToDetail", "getSiteAssets", "getSiteById"]),

        getThisWidget: function() {
            let myWidget = this.currentDashboardWidgets.filter(function(widgetObject) {
                return widgetObject.id === this.widgetId;
            }, this)[0];

            return myWidget;
        },

        getThisWidgetDrawingData: function() {
            let data = this.widgetsDrawingContentData.filter(function(widgetContent) {
                return widgetContent.widgetId === this.widgetId;
            }, this)[0];

            return data ? (data.drawingData || null) : null
        },

        initModalParams() {
            // Set to false all displays items boolean
            this.displaySimpleTableDetail = false;
            this.displayAssetAvailibiltyDetail = false;
            this.displayAssetName = false;
        },

        resetModalParams() {
            this.hasDetailsToShow = false;
        },

        forceCloseModal() {
            //smash params
            this.resetModalParams();
            if (this.widgetId) {
                this.unsetWidgetToDetail();
            } else {
                $("#widgetInfoModal div.modal-body").scrollTop(0);
                $("#widgetInfoModal").modal("hide");
            }

        },

        initTableDetailContent(widgetTypeReference, widgetDetailsData) {
            switch (widgetTypeReference) {
                case "NUMBER_OF_SEARCH":
                    // Set the correct title of widget
                    this.widgetTitle = i18n.t("analytics_SearchDetailWidgetTitle");
                    // Prepare data to display the details by type of widget
                    this.detailTableHeader = [];
                    this.detailTableBody = [];
                    if (widgetDetailsData.length > 0) {
                        // Set the boolean to define what table we must be dislayed
                        this.displaySimpleTableDetail = true;
                        this.hasDetailsToShow = true;
                        this.displayAssetName = false; // Don't contains the asset name
                        // Sort searches by number of search
                        widgetDetailsData = _.sortBy(widgetDetailsData, ["numberSearch"]).reverse();
                        // Create the content of table's header
                        this.detailTableHeader.push(i18n.t("analytics_SearchDetailTableName"));
                        this.detailTableHeader.push(i18n.t("analytics_SearchDetailTableNumber"));
                        this.detailTableHeader.push(i18n.t("analytics_SearchDetailTableType"));
                        // Prepare the content of table's body
                        for (let searchData of widgetDetailsData) {
                            let tableLine = [];
                            tableLine.push(searchData.name);
                            tableLine.push(searchData.numberSearch);
                            // Create the type of search
                            let itemType = [];
                            for (let type of searchData.itemType) {
                                itemType.push(i18n.t("analytics_SearchDetailItemType_" + type));
                            }
                            tableLine.push(itemType.join(", "));
                            // Add new line in table's body
                            this.detailTableBody.push(tableLine);
                        }
                    } else {
                        // No data to display add the empty message
                        this.resetModalParams();
                        this.initModalParams();
                        this.emptyDetailSubLabel = "analytics_emptySearchDetailsSubLabel";
                    }
                    break;
                case "NUMBER_OF_ASSETS_PER_AVAILABILITY":
                    // Set the correct title of widget
                    this.widgetTitle = i18n.t("analytics_assetAvailabilityDetailWidgetTitle");
                    if (widgetDetailsData.hasOwnProperty("ENUM_AVAILABILITY_UNAVAILABLE") || widgetDetailsData.hasOwnProperty("ENUM_AVAILABILITY_AVAILABLE")) {
                        // Set the boolean to define what table we must be dislayed data
                        this.hasDetailsToShow = true;
                        this.displayAssetAvailibiltyDetail = true;
                        // We verify if we have the details of unavailable asset
                        if (widgetDetailsData.hasOwnProperty("ENUM_AVAILABILITY_UNAVAILABLE") && widgetDetailsData.ENUM_AVAILABILITY_UNAVAILABLE.length > 0) {
                            this.detailAssetUnavailableTableHeader = [];
                            this.detailAssetUnavailableTableBody = [];
                            // We prepare the header of unavailable table
                            this.detailAssetUnavailableTableHeader.push(i18n.t("analytics_assetAvailabilityDetailTableCategory"));
                            this.detailAssetUnavailableTableHeader.push(i18n.t("analytics_assetUnavailableDetailTableAssetNumber"));
                            this.detailAssetUnavailableTableHeader.push(i18n.t("analytics_assetUnavailableDetailTableAssetName"));
                            // Prepare the body of table
                            this.detailAssetUnavailableTableBody = this.getAssetsByCategory(widgetDetailsData.ENUM_AVAILABILITY_UNAVAILABLE);
                        }
                        // We verify if we have the details of available asset
                        if (widgetDetailsData.hasOwnProperty("ENUM_AVAILABILITY_AVAILABLE") && widgetDetailsData.ENUM_AVAILABILITY_AVAILABLE.length > 0) {
                            this.detailAssetAvailableTableHeader = [];
                            this.detailAssetAvailableTableBody = [];
                            // We prepare the header of available table
                            this.detailAssetAvailableTableHeader.push(i18n.t("analytics_assetAvailabilityDetailTableCategory"));
                            this.detailAssetAvailableTableHeader.push(i18n.t("analytics_assetAvailableDetailTableAssetNumber"));
                            this.detailAssetAvailableTableHeader.push(i18n.t("analytics_assetAvailableDetailTableAssetName"));
                            // Prepare the body of table
                            this.detailAssetAvailableTableBody = this.getAssetsByCategory(widgetDetailsData.ENUM_AVAILABILITY_AVAILABLE);
                        }

                    } else {
                        this.resetModalParams();
                        this.initModalParams();
                        this.emptyDetailSubLabel = "analytics_emptyAssetAvailabilityDetailsSubLabel";
                    }
                    break;
                case "NUMBER_OF_ASSETS_PER_BATTERY_LEVEL":
                    // Set the correct title of widget
                    this.widgetTitle = i18n.t("analytics_TagBatteryWidgetTitle");
                    // Prepare data to display the details by type of widget
                    this.detailTableHeader = [];
                    this.detailTableBody = [];
                    this.detailAssetNameTableBody = [];
                    if (widgetDetailsData.hasOwnProperty("HIGH_BATTERY") || widgetDetailsData.hasOwnProperty("HIGH_BATTERY") || widgetDetailsData.hasOwnProperty("HIGH_BATTERY")) {
                        // Set the default showing if the table is empty
                        this.emptyDetailSubLabel = "analytics_emptyTagBatteryDetailsSubLabel";
                        this.hasDetailsToShow = false;
                        // Verify if HIGH_BATERY is found
                        if (widgetDetailsData.hasOwnProperty("HIGH_BATTERY") && widgetDetailsData.HIGH_BATTERY.length > 0) {
                            // Set the boolean to define what table we must be displayed
                            this.displaySimpleTableDetail = true;
                            this.hasDetailsToShow = true;
                            this.displayAssetName = true; // The column of asset name can be displayed
                            // Create the content of table's header if is not already created
                            if (this.detailTableHeader.length === 0) {
                                this.detailTableHeader.push(i18n.t("analytics_TagBatteryDetailTableLevel"));
                                this.detailTableHeader.push(i18n.t("analytics_TagBatteryDetailTableAssetsNumber"));
                                this.detailTableHeader.push(i18n.t("analytics_TagBatteryDetailTableAssetsName"));
                            }
                            // Preparation of table body
                            let assetNumber = 0;
                            let assets = [];
                            for (let tagByLevel of widgetDetailsData.HIGH_BATTERY) {
                                assetNumber += 1;
                                // Fetch asset list of level
                                assets.push({id: tagByLevel.id, name: tagByLevel.name});
                            }
                            // Adding the asset number and asset names
                            let tableLine = [];
                            tableLine.push(i18n.t("analytics_TagBatteryDetailTableLevelHigh"));
                            tableLine.push(assetNumber);
                            this.detailTableBody.push(tableLine);
                            this.detailAssetNameTableBody.push(assets);
                        }

                        // Verify if MEDIUM_BATTERY is found
                        if (widgetDetailsData.hasOwnProperty("MEDIUM_BATTERY") && widgetDetailsData.MEDIUM_BATTERY.length > 0) {
                            // Set the boolean to define what table we must be displayed
                            this.displaySimpleTableDetail = true;
                            this.hasDetailsToShow = true;
                            this.displayAssetName = true; // The column of asset name can be displayed
                            // Create the content of table's header if is not already created
                            if (this.detailTableHeader.length === 0) {
                                this.detailTableHeader.push(i18n.t("analytics_TagBatteryDetailTableLevel"));
                                this.detailTableHeader.push(i18n.t("analytics_TagBatteryDetailTableAssetsNumber"));
                                this.detailTableHeader.push(i18n.t("analytics_TagBatteryDetailTableAssetsName"));
                            }
                            // Preparation of table body
                            let assetNumber = 0;
                            let assets = [];
                            for (let tagByLevel of widgetDetailsData.MEDIUM_BATTERY) {
                                assetNumber += 1;
                                // Fetch asset list of level
                                assets.push({id: tagByLevel.id, name: tagByLevel.name});
                            }
                            // Adding the asset number and asset names
                            let tableLine = [];
                            tableLine.push(i18n.t("analytics_TagBatteryDetailTableLevelMedium"));
                            tableLine.push(assetNumber);
                            this.detailTableBody.push(tableLine);
                            this.detailAssetNameTableBody.push(assets);
                        }

                        // Verify if LOW_BATTERY is found
                        if (widgetDetailsData.hasOwnProperty("LOW_BATTERY") && widgetDetailsData.LOW_BATTERY.length > 0) {
                            // Set the boolean to define what table we must be displayed
                            this.displaySimpleTableDetail = true;
                            this.hasDetailsToShow = true;
                            this.displayAssetName = true; // The column of asset name can be displayed
                            // Create the content of table's header if is not already created
                            if (this.detailTableHeader.length === 0) {
                                this.detailTableHeader.push(i18n.t("analytics_TagBatteryDetailTableLevel"));
                                this.detailTableHeader.push(i18n.t("analytics_TagBatteryDetailTableAssetsNumber"));
                                this.detailTableHeader.push(i18n.t("analytics_TagBatteryDetailTableAssetsName"));
                            }
                            // Preparation of table body
                            let assetNumber = 0;
                            let assets = [];
                            for (let tagByLevel of widgetDetailsData.LOW_BATTERY) {
                                assetNumber += 1;
                                // Fetch asset list of level
                                assets.push({id: tagByLevel.id, name: tagByLevel.name});
                            }
                            // Adding the asset number and asset names
                            let tableLine = [];
                            tableLine.push(i18n.t("analytics_TagBatteryDetailTableLevelLow"));
                            tableLine.push(assetNumber);
                            this.detailTableBody.push(tableLine);
                            this.detailAssetNameTableBody.push(assets);
                        }

                    } else {
                        // No data to display add the empty message
                        this.resetModalParams();
                        this.initModalParams();
                        this.emptyDetailSubLabel = "analytics_emptyTagBatteryDetailsSubLabel";
                    }
                    break;
                case "NUMBER_OF_ASSETS_PER_UNIT":
                    // Set the correct title of widget
                    this.widgetTitle = i18n.t("analytics_AssetUnitDetailWidgetTitle");
                    // Prepare the data to display
                    this.detailTableHeader = [];
                    this.detailTableBody = [];
                    this.detailAssetNameTableBody = []; // This detail must be displayed the name of assets
                    if (Object.keys(widgetDetailsData).length > 0) {
                        // Set the boolean to define what table we must be displayed
                        this.displaySimpleTableDetail = true;
                        this.hasDetailsToShow = true;
                        this.displayAssetName = true; // The column which contains the name of asset must be displayed
                        // Create the content of table's header
                        this.detailTableHeader.push(i18n.t("analytics_AssetUnitDetailTableUnitName"));
                        this.detailTableHeader.push(i18n.t("analytics_AssetUnitDetailTableAssetNumber"));
                        this.detailTableHeader.push(i18n.t("analytics_AssetUnitDetailTableAssetName"));
                        // Create the content of table body
                        for (let unit in widgetDetailsData) {
                            let tableLine = [];
                            // Add the unit name
                            tableLine.push(i18n.t(unit));
                            // Add the number of assets
                            tableLine.push(widgetDetailsData[unit].length);
                            // Add the line in table body
                            this.detailTableBody.push(tableLine);
                            // Add the asset in asset name detail table
                            this.detailAssetNameTableBody.push(widgetDetailsData[unit]);
                        }
                        // Sort the details by asset number desc
                        this.detailTableBody = _.sortBy(this.detailTableBody, function (detail) {
                            return detail[1];
                        }).reverse();
                        // Readapt the detail of asset name to the right order
                        this.detailAssetNameTableBody = _.sortBy(this.detailAssetNameTableBody, function (detail) {
                            return detail.length;
                        }).reverse();

                    } else {
                        // No data to display add the empty message
                        this.resetModalParams();
                        this.initModalParams();
                        this.emptyDetailSubLabel = "analytics_emptyAssetUnitDetailsSubLabel";
                    }
                    break;
                case "NUMBER_OF_ASSETS_PER_TAG_TYPE":
                    // Set the correct title of widget
                    this.widgetTitle = i18n.t("analytics_AssetTagTypeDetailWidgetTitle");
                    // Prepare the data to display
                    this.detailTableHeader = [];
                    this.detailTableBody = [];
                    this.detailAssetNameTableBody = [];  // This detail must be displayed the name of assets
                    if (Object.keys(widgetDetailsData).length > 0) {
                        // Set the boolean to define what table we must be displayed
                        this.displaySimpleTableDetail = true;
                        this.hasDetailsToShow = true;
                        this.displayAssetName = true; // The column which contains the name of asset must be displayed
                        // Create the content of table's header
                        this.detailTableHeader.push(i18n.t("analytics_AssetTagTypeDetailTableTagType"));
                        this.detailTableHeader.push(i18n.t("analytics_AssetTagTypeDetailTableAssetNumber"));
                        this.detailTableHeader.push(i18n.t("analytics_AssetTagTypeDetailTableAssetName"));
                        // Create the content of table body
                        for (let tagType in widgetDetailsData) {
                            let tableLine = [];
                            // Add the tag type name
                            if (tagType === "ASSET" || tagType === "CARD" || tagType === "UNIVERSAL" || tagType === "WRISTBAND") {
                                // Add the translation name of tag type
                                tableLine.push(i18n.t("analytics_AssetTagTypeDetailTagType" + tagType));
                            } else  {
                                // Add the default name of tag type other
                                tableLine.push(i18n.t("analytics_AssetTagTypeDetailTagTypeOther"));
                            }
                            // Add the number of assets
                            tableLine.push(widgetDetailsData[tagType].length);
                            // Add the line in table body
                            this.detailTableBody.push(tableLine);
                            // Add the asset in asset name detail table
                            this.detailAssetNameTableBody.push(widgetDetailsData[tagType]);
                        }
                        // Sort the details by asset number desc
                        this.detailTableBody = _.sortBy(this.detailTableBody, function (detail) {
                            return detail[1];
                        }).reverse();
                        // Readapt the detail of asset name to the right order
                        this.detailAssetNameTableBody = _.sortBy(this.detailAssetNameTableBody, function (detail) {
                            return detail.length;
                        }).reverse();
                    } else {
                        // No data to display add the empty message
                        this.resetModalParams();
                        this.initModalParams();
                        this.emptyDetailSubLabel = "analytics_emptyAssetTagTypeDetailsSubLabel";
                    }
                    break;
                case "NUMBER_OF_ASSETS_PER_CATEGORY":
                    // Set the correct title of widget
                    this.widgetTitle = i18n.t("analytics_AssetCategoryDetailWidgetTitle");
                    // Prepare the data to display
                    this.detailTableHeader = [];
                    this.detailTableBody = [];
                    this.detailAssetNameTableBody = [];  // This detail must be displayed the name of assets
                    if (Object.keys(widgetDetailsData).length > 0) {
                        // Set the boolean to define what table we must be displayed
                        this.displaySimpleTableDetail = true;
                        this.hasDetailsToShow = true;
                        this.displayAssetName = true; // The column which contains the name of asset must be displayed
                        // Create the content of table's header
                        this.detailTableHeader.push(i18n.t("analytics_AssetCategoryDetailTableTagCategory"));
                        this.detailTableHeader.push(i18n.t("analytics_AssetCategoryDetailTableAssetNumber"));
                        this.detailTableHeader.push(i18n.t("analytics_AssetCategoryDetailTableAssetName"));
                        // Create the content of table body
                        for (let category in widgetDetailsData) {
                            let tableLine = [];
                            // Add the category name
                            if (widgetDetailsData[category][0].category.isDefault) {
                                // This category is the default category add the translation's name
                                tableLine.push(i18n.t(category));
                            } else {
                                tableLine.push(category);
                            }
                            // Add the number of assets
                            tableLine.push(widgetDetailsData[category].length);
                            // Add the line n table body
                            this.detailTableBody.push(tableLine);
                            // Add the asset in asset name detail table
                            this.detailAssetNameTableBody.push(widgetDetailsData[category]);
                        }
                        // Sort the details by asset number desc
                        this.detailTableBody = _.sortBy(this.detailTableBody, function (detail) {
                            return detail[1];
                        }).reverse();
                        // Readapt the detail of asset name to the right order
                        this.detailAssetNameTableBody = _.sortBy(this.detailAssetNameTableBody, function (detail) {
                            return detail.length;
                        }).reverse();
                    } else {
                        // No data to display add the empty message
                        this.resetModalParams();
                        this.initModalParams();
                        this.emptyDetailSubLabel = "analytics_emptyAssetCategoryTypeDetailsSubLabel";
                    }
                    break;
                case "ASSETS_THESHOLD_IN_GEOFENCE":
                    // Set the correct title of widget
                    this.widgetTitle = i18n.t("analytics_thresholdDetailWidgetTitle", { geofenceName: this.getGeofenceById(this.widgetObj.pathParams.GEOFENCE_ID).name || "" });
                    // Prepare data to display the details by type of widget
                    this.detailTableHeader = [];
                    this.detailTableBody = [];
                    if (widgetDetailsData && widgetDetailsData.length > 0) {
                        // Set the boolean to define what table we must be dislayed
                        this.displaySimpleTableDetail = true;
                        this.hasDetailsToShow = true;
                        this.displayAssetName = false; // Asset name cannot displayed in list
                        // Create the content of table's header
                        this.detailTableHeader.push(i18n.t("analytics_ThresholdDetailTableAssetName"));
                        this.detailTableHeader.push(i18n.t("analytics_ThresholdDetailTableAssetCategory"));
                        this.detailTableHeader.push(i18n.t("analytics_ThresholdDetailTableAssetSubcategory"));
                        this.detailTableHeader.push(i18n.t("analytics_ThresholdDetailTableAssetUnit"));
                        this.detailTableHeader.push(i18n.t("analytics_ThresholdDetailTableAssetTagMac"));
                        this.detailTableHeader.push(i18n.t("analytics_ThresholdDetailTableAssetTagType"));

                        // Prepare the content of table's body
                        for (let searchData of widgetDetailsData) {
                            let tableLine = [];
                            // Add asset name
                            tableLine.push(searchData.name);
                            // Add asset category name
                            if (searchData.assetcategory && searchData.assetcategory.name) {
                                tableLine.push(i18n.t(searchData.assetcategory.name));
                            } else {
                                tableLine.push("N/A");
                            }
                            // Add asset subcategory name
                            if (searchData.assetsubcategory && searchData.assetsubcategory.name) {
                                tableLine.push(i18n.t(searchData.assetsubcategory.name));
                            } else {
                                tableLine.push("N/A");
                            }
                            // Add the asset unit
                            if (searchData.assetUnit) {
                                if (searchData.assetUnit.isDefault) {
                                    tableLine.push(i18n.t(searchData.assetUnit.name));
                                } else {
                                    tableLine.push(searchData.assetUnit.name);
                                }
                            }
                            // Add the mac address of asset tag
                            if (searchData.tag && searchData.tag) {
                                tableLine.push(searchData.tag.macAddress);
                            } else {
                                tableLine.push("N/A");
                            }
                            // Add the name of tag type
                            if (searchData.tag && searchData.tag.tagType && searchData.tag.tagType.name) {
                                tableLine.push(searchData.tag.tagType.name);
                            } else {
                                tableLine.push("N/A");
                            }
                            // Add new line in table's body
                            this.detailTableBody.push(tableLine);
                        }
                    } else {
                        // No data to display add the empty message
                        this.resetModalParams();
                        this.initModalParams();
                        this.emptyDetailSubLabel = "analytics_emptySearchDetailsSubLabel";
                    }
                    break;
                default:
                    this.resetModalParams();
                    this.initModalParams();
                    break;
            }
        },

        getAssetsByCategory(assets) {
            let assetsByCategory = [];
            for (let asset of assets) {
                let assetFind = _.find(this.siteAssets, {id: asset.id});
                if (assetFind) {
                    const category = assetFind.assetcategory.isDefault ? i18n.t(assetFind.assetcategory.name) : assetFind.assetcategory.name;
                    // Verify if the category has already stored
                    let categoryFindIndex = _.findIndex(assetsByCategory, {category: category});
                    if (categoryFindIndex != -1) {
                        // Category has already stored we can add the asset in existing
                        assetsByCategory[categoryFindIndex].assets.push({id: assetFind.id, name: assetFind.name});
                        assetsByCategory[categoryFindIndex].assetCount += 1;
                    } else {
                        // No stored we can add the new element
                        assetsByCategory.push({
                            category: category,
                            assetCount: 1,
                            assets: [{id: assetFind.id, name: assetFind.name}]
                        })
                    }

                }
            }
            return assetsByCategory;
        },

        onClickAssetName(assetId) {
            // Set the variables which permit to decide what view must be displayed
            let assetFind = _.find(this.siteAssets, {id: assetId});
            let siteInProduction = this.currentSite.isInProduction;
            this.forceCloseModal();
            // Go to edit vue
            setTimeout(() => {
                if (siteInProduction && assetFind.tag) {
                    let tagMacAddress = assetFind.tag.macAddress;
                    this.$router.push({ name: "assetsTrackingDashboard", query: { highlight: tagMacAddress } });
                } else {
                    this.$router.push({ name: "editAsset", params: { assetId: assetFind.id } });
                }
            }, 50);
        },

        isHyperlinkAssetName() {
            let hyperlink = false;
            if (this.currentSite && this.currentSite.isInProduction && this.hasAccess(this.siteId, 'ASSET_SEARCH.SEARCH')) {
                hyperlink = true;
            } else if (this.hasAccess(this.siteId, 'ASSET_PROVISIONING.ASSET_MANAGEMENT')) {
                hyperlink = true;
            }
            console.log("Component(widgetDetailsModal)::isHyperlinkAssetName() - returns " + hyperlink);
            return hyperlink;
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-emptylist": EmptyList
    }
};
</script>

<style scoped>
    .overflowContent {
        max-height: 400px;
        overflow-y: scroll;
    }
</style>