<template>
    <div class="generic-widget-heatmap">
        <div class="kt-portlet kt-portlet--height-fluid heatmapChart" :class="isDarkMode ? 'portletDark' : ''">
            <div class="kt-widget14">
                <div class="genericWidgetHeader">
                    <div class="kt-widget14__header">
                        <div class="kt-widget14__title genericWidgetTitle" :class="isDarkMode ? 'textWhiteForDark' : ''">
                            {{ this.widgetTitle }}
                        </div>
                        <div class="kt-widget14__desc genericWidgetTitle" :class="isDarkMode ? 'textWhiteForDark' : ''">
                            <i v-if="widgetTimeWindow" class="la la-clock-o marginR5"></i>
                            <span v-if="widgetTimeWindow == '5'">{{ $tc("analytics_selectHeatmapTW_minutes", 5) }}</span>
                            <span v-if="widgetTimeWindow == '10'">{{ $tc("analytics_selectHeatmapTW_minutes", 10) }}</span>
                            <span v-if="widgetTimeWindow == '15'">{{ $tc("analytics_selectHeatmapTW_minutes", 15) }}</span>
                            <span v-if="widgetTimeWindow == '30'">{{ $tc("analytics_selectHeatmapTW_minutes", 30) }}</span>
                            <span v-if="widgetTimeWindow == '60'">{{ $tc("analytics_selectHeatmapTW_hours", 1) }}</span>
                            <span v-if="widgetTimeWindow == '120'">{{ $tc("analytics_selectHeatmapTW_hours", 2) }}</span>
                            <span style="margin-left: 5px;margin-right: 5px;" v-if="this.widgetAssets">|</span>
                            <i v-if="this.widgetAssets" class="la la-tag" style="margin-right: 3px;"></i>
                            <span v-if="this.widgetAssets=='all'">{{ $t("analytics_selectAssetCategory_all") }}</span>
                            <span v-else-if="this.widgetAssets">{{ $t(this.widgetAssets) }}</span>
                        </div>
                    </div>
                    <div class="genericWidgetToolbar">
                        <div data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('common_moreActions')" class="dropdown dropdown-inline">
                            <button type="button" class="btn btn-clean btn-icon btn-sm btn-icon-md moreButtonChartMain" :class="isDarkMode ? 'backgroundMoreBtnDarkHover' : ''" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="flaticon-more moreButtonChart" :class="isDarkMode ? 'textWhiteForDark' : ''"></i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right" style="">
                                <a class="dropdown-item" href="#" @click="onEditWidget"><i class="la la-cog"></i> {{ $t("analytics_settings") }}</a>
                                <a class="dropdown-item" href="#" @click="onDeleteWidget"><i class="la la-trash"></i> {{ $t("analytics_delete") }} </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="kt-widget14__content genericWidgetContentChart">
                    <div class="kt-widget14__chart" style="height: 100%; width: 100%">
                        <app-locationmap mode="analyticswidget"></app-locationmap>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
import LocationMap from "../map/locationmap.vue";
export default {
    data() {
        return {
            displayError: false,
            widgetTitle: null,
            widgetDesc: null,
            refreshInterval: null,
            refreshSchedulerId: null,
            isOngoingFetch: false,
            widgetTimeWindow: null,
            widgetAssets: null
        };
    },
    created: function() {
        console.log("Component(widgetHeatMap)::created() - called - widgetId: " + this.widgetId);
        this.getWidgetTitle();
    },
    destroyed: function() {
        console.log("Component(widgetHeatMap)::destroyed() - called - widgetId: " + this.widgetId);
        if (this.refreshSchedulerId) {
            clearInterval(this.refreshSchedulerId);
        }
    },
    props: {
        widgetId: {
            type: String
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --

        currentDashboardWidgets: {
            deep: true,
            handler(newValue) {
                this.getWidgetTitle(newValue);
            }
        },

        currentDashboard: {
            deep: true,
            immediate: true,
            handler(newValue) {
                this.refreshInterval = newValue ? (parseInt(newValue.refreshInterval) || null) : null;
            }
        },

        widgetsDrawingContentData: {
            deep: true,
            handler(newValue) {
                var thisWidgetContent = newValue.filter(function(widgetContent) {
                    return widgetContent.widgetId === this.widgetId;
                }, this)[0] || null;
                var thisWidget = this.getThisWidget();
                this.widgetTimeWindow = thisWidget.queryParams.timeWindow;
                this.widgetAssets = thisWidget.queryParams.category;
                this.setHeatmapData(thisWidgetContent ? (thisWidgetContent.drawingData || null) : null);
            }
        },

        refreshInterval: {
            immediate: true,
            handler(newValue) {
                console.log("Component(widgetHeatMap)::watch(refreshInterval) handle with :", newValue);
                this.scheduleRefresh();
            }
        },

        widgetsLoadingState: {
            //deep: true,
            immediate: true,
            handler(newValue) {
                if (newValue.hasOwnProperty(this.widgetId)) {
                    this.isOngoingFetch = newValue[this.widgetId] ? true : false;
                }
            }
        }

    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["currentDashboard", "currentDashboardWidgets","widgetsDrawingContentData",  "widgetsLoadingState", "isDarkMode"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["showDeleteModal", "getWidgetContent", "setWidgetToEdit", "setHeatmapData"]),

        getThisWidget: function(listWidgets) {
            var myWidget = (listWidgets || this.currentDashboardWidgets).filter(function(widgetObject) {
                return widgetObject.id === this.widgetId;
            }, this)[0];
            return myWidget;
        },

        onDeleteWidget: function () {
            this.showDeleteModal({ textContent: i18n.t("analytics_deleteWidgetConfirmText", { widgetName: this.widgetTitle }), actionName: "DELETE_WIDGET", params: { siteId: this.$route.params.siteId, dashboardId: this.$route.params.dashboardId, widgetId: this.widgetId } });
        },

        onEditWidget: function() {
            this.setWidgetToEdit(this.widgetId);
        },

        scheduleRefresh: function() {
            if (this.refreshSchedulerId) {
                clearInterval(this.refreshSchedulerId);
            }
            this.smartFetchData();
            if (parseInt(this.refreshInterval)) {
                var self = this;
                this.refreshSchedulerId = setInterval(function() {
                    console.log("Component(widgetHeatMap)::scheduleRefresh() -> widget " + self.widgetId + " setInterval called at " + self.refreshInterval + " seconds");
                    self.smartFetchData();
                }, parseInt(this.refreshInterval)*1000);
            }
        },

        smartFetchData() {
            if(!this.isOngoingFetch) {
                this.getWidgetContent(this.widgetId);
            }
        },

        formatWidgetTitle(thisWidget) {
            var isDefaultName = thisWidget.widgetType.defaultNameKey === thisWidget.customNameToDisplay;
            this.widgetTitle = isDefaultName ? i18n.t(thisWidget.customNameToDisplay) : thisWidget.customNameToDisplay;
        },

        getWidgetTitle(newValue) {
            const thisWidget = this.getThisWidget(newValue);
            if (thisWidget) {
                this.formatWidgetTitle(thisWidget);
                this.widgetDesc = thisWidget.widgetType.defaultNameKey + "_desc";
            }
        }
    },
    components: {
        "app-locationmap": LocationMap
    }
};
</script>
